import styled from 'styled-components';

export const Title = styled.div`
color: var(--text);
font-family: var(--semiBold);
font-size: 14px;
line-height: 22px;
`;

export const StyledInputTag = styled.div`
  background: #fff;
  border: 1px solid #CACFD0;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 8px 8px 12px ;
  font-family: 'var(--regular)';
  font-size: 14px;
  input {
    border: none;
    width: 100%;
  }
`;

export const TagsList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: var(--regular);
`;

export const Tag = styled.div`
  align-items: center;
  background: #70A3EC;
  border-radius: 7px;
  color: #000;
  display: flex;
  font-weight: 400;
  font-family: var(--regular);
  font-size: 14px;
  list-style: none;
  margin: 0px 5px 2.5px 0px;
  padding: 2px 6px;
`;

export const TagButton = styled.div`
margin-left: 5px;
cursor: pointer;
`;

export const InputWrapper = styled.div`
  background: none;
  flex-grow: 2;
  padding: 0;
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  color: black;
  width: 100%;
  font-size: 14px;
`;

export const Image = styled('img')`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-top: 2px;
  cursor: pointer;
`