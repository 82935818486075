import { useEffect, useState } from 'react'
import { api } from '../api'

export const useReportMaterials = ({ materialType, reportCompanyId, reportId, isLicenseSource, determinationId, params, isNeedHistory }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [materials, setMaterials] = useState([])


  useEffect(() => {
    setLoading(true)
    setError(false)
    const url = isLicenseSource || isNeedHistory ?
      !isNeedHistory &&
        params.tabName === 'renewals_reporting'
        || params.tabName === 'assigned'
        || params.tabName === 'closed'
        || params.tabName === 'all_renewals'
        ? `/renewals_reporting/${reportCompanyId}/${materialType}/`
        : `/determinations/${determinationId}/${materialType}/`
      : `/report_company/${reportCompanyId}/${materialType}/`
    api(
      url,
      {},
      'GET'
    ).then(res => {
      setMaterials(res[0])
      setLoading(false)
    }).catch(e => {
      setError(true)
    })
  }, [materialType, reportCompanyId, reportId]);

  return { loading, error, materials }
}