import React from 'react'
import { Block, DocumentsPlaceholder, EmptyBlock, InfoBlock } from '../right.style'
import { Spin, Empty } from 'antd'
import Document from './document'
import { useReportMaterials } from '../../../../hooks/useReportMaterials'
import { highlightSearchedWords } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { useParams } from 'react-router-dom'

const ReportMaterials = ({ reportCompanyId, materialType, reportId, isMyTasks, isLicenseSource, determinationId, isNeedHistory }) => {

  const params = useParams()

  const {
    loading,
    error,
    materials,
  } = useReportMaterials({ reportId, reportCompanyId, materialType, isLicenseSource, determinationId, params, isNeedHistory });
  const store = useStore()

  const modifiedHtmlText =
    isMyTasks
      ? materials?.description && highlightSearchedWords(materials?.description.replace(/<a /g, '<a target="_blank" '), store?.searchableObjectTasks?.search)
      : materials?.description && highlightSearchedWords(materials?.description.replace(/<a /g, '<a target="_blank" '), store?.searchableObject?.search);
  if (loading) return <Spin style={{ marginTop: 20 }} />
  if (!materials) return <EmptyBlock>Not Specified.</EmptyBlock>
  return (
    <Block
      style={{
        marginTop: '1em',
        paddingBottom: '1em',
        minHeight: 120,
      }}
      bordered>
      {!materials && (
        <DocumentsPlaceholder>
          There are no documents associated with this item yet.
        </DocumentsPlaceholder>
      )}
      {materials.description &&
        <InfoBlock dangerouslySetInnerHTML={{ __html: modifiedHtmlText }}></InfoBlock>
      }
      {materials.files && materials.files.length > 0 && (
        materials.files.map((file) => (
          <Document
            file={file}
          />
        )
        )
      )}
    </Block>
  )
}

export default ReportMaterials
