import React from 'react';
import Modal from '../../../../components/common/ModalNew/index'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import { Cancel } from '../Tasks/Modal/style'

const ConfirmModal = ({ onClose,
  onSubmit,
  title,
  text = 'Are you sure you want to continue without saving?',
  confirmBtnText = 'Yes',
  titleStyle = { fontSize: '1.5em' },
  textStyle = { fontSize: '1.5em' }
}) => {
  const modalMessege = text ? text : 'Are you sure you want to continue without saving?'
  return (
    <Modal
      styleWrap={{
        width: 'auto',
        maxWidth: 600,
        height: 'auto',
        minHeight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid var(--grey)',

      }}
      onClose={onClose}>
      {title && <Label style={titleStyle}>{title}</Label>}
      <Label style={textStyle}>{modalMessege}</Label>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: text ? 50 : 150 }}>
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button text={confirmBtnText} onClick={() => onSubmit()} />
      </div>
    </Modal>
  )
}

export default ConfirmModal
