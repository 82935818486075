import Cookies from 'universal-cookie'
import { getError, formatDate } from './helpers/helpers'
import { message } from 'antd'
import { is } from 'immutable'


let retries = 0

export const api = async (url, body, method, noAuth, file) => {
  const cookies = new Cookies()

  const csrftoken = cookies.get('csrftoken')
  let token = cookies.get('pbm_token')

  let options = {
    method,
    headers: file
      ? {
        Authorization: `Token ${token}`,
        'X-CSRFToken': csrftoken,
      }
      : !noAuth
        ? {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        }
        : {
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
  }

  if (
    method === 'PATCH' ||
    method === 'POST' ||
    method === 'DELETE' ||
    method === 'PUT'
  ) {
    options = {
      ...options,
      body: file ? body : JSON.stringify(body),
    }
  }

  return fetch(`${process.env.REACT_APP_API_DEV}${url}`, options).then(
    (res) => {
      if (!res.ok) {
        if (res.status === 400) {
          if (res.headers.get('Content-Type') === 'application/json') {
            return res
              .json()
              .then((data) => ({ errors: data, status: res.status }))
          }
        }
        if (res.status === 401) {
          // return removeCookiesAndGoToRegister()
          // return api(url, body, method, noAuth);
        }

        if (res.status === 403) {
          return { errors: { trial_ended: ['Trial ended'], status: 403 } }
        }

        if (res.status === 500) {
          return { errors: { server: ['Server error'] } }
        }

        return res.json().then((data) => ({ errors: data, status: res.status }))
      }

      retries = 0

      if (res.status === 204) {
        return { deleted: true }
      }
      return res.json()
    }
  )
}

export const getCompanyId = async () => {
  const cookies = new Cookies()

  const csrftoken = cookies.get('csrftoken')
  const token = cookies.get('pbm_token')

  return await fetch(`${process.env.REACT_APP_API_DEV}/companies/`, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrftoken,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    if (!res.ok) {
      return res
        .json()
        .then((data) => ({ error: true, ...data, status: res.status }))
    }
  })
}

export const downloadDoc = async (url,
  name,
  isReportSource = false,
  isLicenseSource = false,
  isRenewalsReporting = false,
  report_company_id = null,
  report_id = null,
  admin_actions = false,
  isNeedHistory = false,
  license_id = null,
) => {
  const cookies = new Cookies()
  const csrftoken = cookies.get('csrftoken')
  const token = cookies.get('pbm_token')
  const requestUrl = isReportSource
    ? `${process.env.REACT_APP_API_DEV}/report_company/${report_company_id}/files/${url}/`
    : isLicenseSource ?
      admin_actions ?
        `${process.env.REACT_APP_API_DEV}/${url}`
        : isRenewalsReporting && !isNeedHistory ?
          `${process.env.REACT_APP_API_DEV}/renewals_reporting/${report_company_id}/files/${url}/`
          : `${process.env.REACT_APP_API_DEV}/license_company/${isNeedHistory ? license_id : report_company_id}/files/${url}${isNeedHistory ? '/?for_history=true' : '/'}`
      : `${process.env.REACT_APP_API_DEV}/fetch/${url}`
  const response = await fetch(requestUrl, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrftoken,
      'Content-Type': 'application/json',
    },
  })

  if (response.status === 200) {
    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = name
    document.body.appendChild(link)
    link.click()
    link.remove()
  } else {
    message.error('Error downloading file')
  }
}

export const downloadAllDocs = async (id, status, name = 'archive.zip', isReportSource = false, isLicenseSource = false, isRenewalsReporting = false, isNeedHistory = false, currentId) => {
  const cookies = new Cookies()
  const csrftoken = cookies.get('csrftoken')
  const token = cookies.get('pbm_token')
  const requestUrl =
    isReportSource
      ? `${process.env.REACT_APP_API_DEV}/report_company/${id}/files/download_all/?status=${status}`
      : isLicenseSource ?
        isRenewalsReporting ?
          `${process.env.REACT_APP_API_DEV}/renewals_reporting/${isNeedHistory ? currentId : id}/files/download_all/?status=${status}${isNeedHistory ? '&for_initial_application_history=true' : ''}`
          : `${process.env.REACT_APP_API_DEV}/license_company/${id}/files/download_all/?status=${status}${isNeedHistory ? '&for_initial_application_history=true' : ''}`
        : `${process.env.REACT_APP_API_DEV}/legislative_file/download_all/?legislative_id=${id}`

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers: {
      Authorization: `Token ${token}`,
      'X-CSRFToken': csrftoken,
    },
  })

  if (response.status === 200) {
    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = name;
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}

export const removeCookiesAndGoToRegister = () => {
  const cookies = new Cookies()
  retries = 0

  const allCookies = cookies.getAll()

  for (let key in allCookies) {
    if (key === 'pbm_token') {
      cookies.remove(key)
    }
  }

  window.location.href = '/home'
}

export const patchCompany = (store) => {
  api(
    `/companies/${store.company[0].id}/`,
    {
      start_date: formatDate(new Date(), true),
      have_access_to_tracking: true,
    },
    'PATCH'
  ).then((data) => {
    if (!data.errors) {
      store.setSingleValue('company', [data])
    }
  })
}

export const startTrial = (isResource, store, history) => {
  if (!isResource) {
    api('/payments/start_trial/', {}, 'POST').then((data) => {
      if (data.errors) return message.error(getError(data))
      store.getCompany(() => {
        store.getUser(() => {
          history.push(
            isResource ? '/resource/table/guides' : '/tracking/main'
          )
        })
      })
    })
  } else {
    history.push(
      isResource ? '/resource/table/guides' : '/tracking/main'
    )
  }
}
