import React from 'react'
import Moment from 'react-moment'
import { useHistory, useParams } from 'react-router-dom'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../../helpers/helpers'
import useModal from '../../../../../hooks/useModal'
import { useStore } from '../../../../../mobx-store/context'
import ModalCompleted from '../Modal/ModalCompleted'
import { Item } from './style'


const List = ({
  tasks,
  currentTask,
  setCurrentTask,
  onModalOpen,
  onComplete,
  taskID,
  complianceItem,
  data,
  isMyTasks,
  getTasks,
  activeTab,
  setEdit,
  isReportSource = false,
  isLicenseSource = false,
  isRenewalsReporting = false,
  setShouldComplete,
  disabled,
}) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const { visible, handleOpen, handleClose } = useModal()
  const isCompleted = (item) => item.status === 'complete'
  const handleCurrentTask = (item) => setCurrentTask(item)

  const onClick = (item) => {
    if (isCompleted(item)) {
      handleCurrentTask(item)
      handleOpen()
    } else {
      handleCurrentTask(item)
      onModalOpen(item)
    }
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';

    const routePath = isReportSource && !reportInLocalStorage
      ? '/report-source/'
      : isLicenseSource || reportInLocalStorage
        ? '/license-source/'
        : '/tracking/';

    const tabRoutes = {
      closed: 'closed',
      all: 'all',
      myTasks: 'myTasks',
      main: 'main',
      apcd: 'apcd',
      monitor: 'monitor',
      na: 'na',
      need: 'need',
      not_assessed: 'not_assessed',
      renewals_reporting: 'renewals_reporting',
      assigned: 'assigned',
      pending_tasks: 'pending_tasks',
      all_renewals: 'all_renewals',
      held: 'held',
      all_renewals: 'all_renewals',
      ia_all: 'ia_all',
      ia_unassigned: 'ia_unassigned',
      ia_in_progress: 'ia_in_progress',
      ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
      ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
      ia_in_progress_deficiency: 'ia_in_progress_deficiency',
    };

    const routeType = isMyTasks
      ? tabRoutes[params.tabName] || 'myTasks'
      : tabRoutes[activeTab] || 'closed';

    const queryParam = isReportSource && localStorage.getItem('reportInLs') !== 'true'
      ? 'report='
      : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
        ? 'license_item='
        : 'compliance_item=';
    const complianceItemId = complianceItem || params.id;
    const taskID = item.id;
    const route = `${routePath}${routeType}?${queryParam}${complianceItemId}&taskID=${taskID}`;
    history.push(route);
  }

  const determineRoutePath = () => {
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';
    if (isReportSource && !reportInLocalStorage) {
      return '/report-source/';
    }
    if (isLicenseSource || reportInLocalStorage) {
      return '/license-source/';
    }
    return '/tracking/';
  };

  const determineRouteType = () => {
    const routeMap = {
      main: 'main',
      apcd: 'apcd',
      all: 'all',
      monitor: 'monitor',
      na: 'na',
      need: 'need',
      not_assessed: 'not_assessed',
      renewals_reporting: 'renewals_reporting',
      assigned: 'assigned',
      pending_tasks: 'pending_tasks',
      all_renewals: 'all_renewals',
      held: 'held',
      all_renewals: 'all_renewals',
      ia_all: 'ia_all',
      ia_unassigned: 'ia_unassigned',
      ia_in_progress: 'ia_in_progress',
      ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
      ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
      ia_in_progress_deficiency: 'ia_in_progress_deficiency',
    };

    if (isMyTasks) {
      return activeTab === 'closed'
        ? 'closed'
        : activeTab === 'all'
          ? 'all'
          : 'myTasks';
    }

    return routeMap[activeTab] || 'closed';
  };

  const determineQueryParam = () => {
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';
    if (isReportSource && !reportInLocalStorage) {
      return 'report';
    }
    if (isLicenseSource || reportInLocalStorage) {
      return 'license_item';
    }
    return 'compliance_item';
  };

  const handleModalClose = () => {
    handleClose();
    const routePath = determineRoutePath();
    const routeType = determineRouteType();
    const queryParam = determineQueryParam();
    const complianceItemId = complianceItem || params.id;

    const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}`;
    history.push(route);
  };

  const fetchRoutePath = () => {
    const reportInLocalStorage = localStorage.getItem('reportInLs') === 'true';
    if (isReportSource && !reportInLocalStorage) return '/report-source/';
    if (isLicenseSource || reportInLocalStorage) return '/license-source/';
    return '/tracking/';
  };

  const resolveRouteType = () => {
    const routeMap = {
      main: 'main',
      apcd: 'apcd',
      all: 'all',
      monitor: 'monitor',
      na: 'na',
      need: 'need',
      not_assessed: 'not_assessed',
      renewals_reporting: 'renewals_reporting',
      assigned: 'assigned',
      pending_tasks: 'pending_tasks',
      all_renewals: 'all_renewals',
      held: 'held',
      ia_all: 'ia_all',
      ia_unassigned: 'ia_unassigned',
      ia_in_progress: 'ia_in_progress',
      ia_in_progress_ready_for_final_review: 'ia_in_progress_ready_for_final_review',
      ia_in_progress_submitted_to_govt: 'ia_in_progress_submitted_to_govt',
      ia_in_progress_deficiency: 'ia_in_progress_deficiency',
    };
    return isMyTasks ? 'myTasks' : routeMap[activeTab] || 'closed';
  };

  const resolveQueryParam = () => {
    if (isReportSource) return 'report';
    if (isLicenseSource) return 'license_item';
    return 'compliance_item';
  };

  const handleTaskClick = (item, isCompletedAction) => {
    handleCurrentTask(item);

    if (isCompletedAction) {

      handleOpen();

    } else {
      if (localStorage.getItem('taskReOpen') === 'true') {
        onModalOpen(item);
      } else {
        if (data.status === 'closed' || disabled) return;
        onComplete();
      }
    }

    const routePath = fetchRoutePath();
    const routeType = resolveRouteType();
    const queryParam = resolveQueryParam();
    const complianceItemId = complianceItem || params.id;
    const taskID = item.id;

    const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}&taskID=${taskID}`;
    history.push(route);
  };

  const isAdminOrComplianceOwner = checkRole([store.user?.[0]?.member_type], ['admin', 'compliance_owner', 'owner']);
  const isAdminOrReportOwner = checkRole([store.user?.[0]?.report_source_role], ['admin', 'report_owner', 'owner']);
  const isAdminorLicenseOwner = checkRole([store.user?.[0]?.license_source_role], ['admin', 'license_owner']);
  const accessAllowed = isAdminOrComplianceOwner || isAdminOrReportOwner || isAdminorLicenseOwner;

  return (
    <div>
      {visible && (
        <ModalCompleted
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          isRenewalsReporting={isRenewalsReporting}
          data={currentTask}
          onClose={handleModalClose}
          tasks={tasks}
          taskID={taskID}
          complianceItem={complianceItem || params.id}
          getTasks={getTasks}
          handleTaskClick={handleTaskClick}
          setShouldComplete={setShouldComplete}
          disabled={disabled}
        />
      )}
      <Item
        style={{
          fontFamily: 'var(--bold)',
        }}
      >
        <div>Assigned To:</div>
        <div>Task:</div>
        <div style={{ paddingLeft: 20 }}>Due:</div>
        <div>Status:</div>
      </Item>
      {tasks?.map((item) => {
        return (
          <Item>
            <div>{store.getMember(item.assignee)}</div>
            {isReportSource || isLicenseSource ?
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {item.type === 'get_ready_task' &&
                  <div style={{
                    textDecoration: 'none !important',
                    textDecorationColor: '#fff',
                    fontFamily: 'var(--medium)',
                    color: 'var(--blue)',
                  }}>
                    Checklist Task:
                  </div>
                }
                <div
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    maxWidth: 300,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={() => { onClick(item) }}
                >
                  {
                    item.description.includes(item.task_template)
                      ? item.description
                      : `${item.task_template ? item.task_template + ' ' : ''}${item.description}`
                  }
                </div>
              </div>
              : isLicenseSource ?
                disabled ?
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        cursor: 'pointer',
                        maxWidth: 350,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.description}
                    </div>
                  </div>
                  : <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        maxWidth: 350,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      onClick={() => { onClick(item) }}
                    >
                      {item.description}
                    </div>
                  </div>
                : <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {item.type !== 'default' && !isLicenseSource &&
                    <div style={{
                      textDecoration: 'none !important',
                      textDecorationColor: '#fff',
                      fontFamily: 'var(--medium)',
                      color: 'var(--blue)',
                    }}>
                      Consideration Task:
                    </div>
                  }
                  <div
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      maxWidth: 350,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={() => { onClick(item) }}
                  >
                    {item.description.includes(item.task_template)
                      ? item.description
                      : `${item.task_template ? item.task_template + ' ' : ''}${item.description}`
                    }
                  </div>
                </div>

            }
            <div style={{ paddingLeft: 20 }}>
              <Moment format={'MM/DD/YYYY'}>{item.due_date}</Moment>
            </div>
            <div
              style={{
                textDecoration: item.status === 'complete' && 'underline',
                cursor: item.status === 'complete' && 'pointer',
              }}
            >
              {isCompleted(item) ? (
                <div
                  style={{ fontFamily: 'var(--regular)', fontSize: '0.875em' }}
                  onClick={() => handleTaskClick(item, true)}
                >
                  Completed
                </div>
              ) : (
                disabled ? (
                  <div style={{ fontFamily: 'var(--regular)', fontSize: '14px' }}>
                    {item.status[0].toUpperCase() + item.status.slice(1)}
                  </div>
                ) : (
                  (accessAllowed || store.user?.[0]?.id === item.assignee) && (
                    <ButtonOrange
                      text={'Complete task'}
                      onClick={() => handleTaskClick(item, false)}
                    />
                  )
                )
              )}
            </div>
          </Item>
        )
      })}
    </div>
  )
}

export default List
