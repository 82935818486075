import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Cancel, Subtitle } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'
import TextEditor from '../HeldAdminItemAddModal/TextEditor'
import { message } from 'antd'

const MoveLicenseModal = ({
  onClose,
  id,
  currentPage,
  moveFrom = 'held'
}) => {
  const store = useStore()
  const { control, handleSubmit, watch } = useForm()
  const [details, setDetails] = useState('')

  const status = watch('status');


  const onSubmit = (values) => {
    if (isEmpty) return
    const url = `/license_company/${id}/${moveFrom === 'held' ? 'remove_license_from_held' : 'remove_license_from_initial_application'}/`
    const obj = {
      move_to: values.status,
      ...(moveFrom === 'held' && { reason: details })
    }
    api(url, obj, 'POST').then((data) => {
      if (!data.errors) {
        store.loadLegislatives({
          page: currentPage,
          page_size: 20,
        }, false, true);
        moveFrom !== 'held' && store.setSingleValue('currentLegislative', data)
        onClose()
        message.success('License moved successfully')
      }
    })
  }

  const title = moveFrom === 'held' ? 'This License Is No Longer Held' : 'Move License'
  const subTitle = moveFrom === 'held'
    ? 'This will remove the renewal and report items that are currently being tracked.'
    : 'You are trying to change the status from Initial Application to Pending Assessment.'

  function convertAndFilterArray(arr, moveFrom) {
    return arr
      .filter(subArray =>
        subArray[0] !== 'held' && (moveFrom === 'held' || subArray[0] !== 'needed')
      )
      .map(subArray => ({
        id: subArray[0],
        name: subArray[1]
      }));
  }

  const isEmpty = !status || (moveFrom === 'held' && !/[a-zA-Z0-9]/.test(details.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').trim()));

  return (
    <ModalWrap onClose={onClose}>
      <Label style={{ marginBottom: 10 }}>{title}</Label>
      <Subtitle style={{ marginBottom: 10 }}>{subTitle}</Subtitle>
      <Input
        label={'Move License'}
        placeholder={'Select an Option'}
        control={control}
        type={'select'}
        options={convertAndFilterArray(store?.license_source_statuses, moveFrom)}
        dropdownStyle={{ zIndex: 100000 }}
        name={'status'}
        required={true}
        smallLabel
      />
      {moveFrom === 'held' && (
        <TextEditor
          title={'Summary'}
          updateDetails={setDetails}
          placeholderText='Add details for why this license is no longer held.'
          edit={false}
          setIsEmpty={() => { }}
          initialText={''}
        />
      )}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          disabled={isEmpty}
          text={'Move License'}
          style={{ marginLeft: 'auto' }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </ModalWrap>
  )
}

export default MoveLicenseModal
