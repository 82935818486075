import React, { useEffect } from 'react'
import { Title } from './style'
import CheckboxComponent from './CheckboxList'
import { EmptyBlock } from '../../right.style'
import { useStore } from '../../../../../mobx-store/context'
import { observer } from 'mobx-react-lite'
const GetReady = observer(({ title, reportCompanyId, handleOpen, setAssigneNew, getReadyData, receiveGetReadyData, assignee, isLicenseSource, renewals = false, determinationId, isNeedHistory, license_company_id }) => {
  const store = useStore()


  useEffect(() => {
    receiveGetReadyData(isLicenseSource, !isLicenseSource, renewals, determinationId, isNeedHistory, license_company_id)
  }, [])

  return (
    <>
      <Title>
        {title}
      </Title>
      {getReadyData && getReadyData.length > 0 ?
        <CheckboxComponent
          assignee={assignee}
          data={getReadyData}
          reportCompanyId={reportCompanyId}
          getData={receiveGetReadyData}
          handleOpen={handleOpen}
          isLicenseSource={isLicenseSource}
          renewals={renewals}
          isNeedHistory={isNeedHistory}
          setAssigneNew={(value) => setAssigneNew(value)} />
        : <EmptyBlock>Not Specified.</EmptyBlock>
      }
    </>
  )
})

export default GetReady