import React, { useEffect, useState } from 'react'
import {
  CommentAuthor,
  CommentLeft,
  Container,
  RepliesBlock,
  ReplyBlock,
  ReplyButton,
  To,
  ToggleButton,
  Wrap,
} from './comment.style'
import reply from '../../../../assets/images/reply.svg'
import { Text } from '../right.style'
import Button from '../../../../components/common/Button/button'
import { useStore } from '../../../../mobx-store/context'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import Moment from 'react-moment'
import Input from '../../../../components/common/Input'

const Comment = observer(
  ({ grey, isReplied, isReply, item, legislative, parentId, single, last, isReportSource = false, isLicenseSource, isRenewalsReporting, getDataFromHistory = false, disabled }) => {
    const store = useStore()
    const { control, handleSubmit, reset } = useForm()
    const [active, setActive] = useState(false)
    const [hidden, setHidden] = useState(false)


    const modifyContent = (content) => {
      return content.split(' ').map((item) => {
        if (item.charAt(0) === '@') {
          return '<mention>' + item + '</mention>'
        }
        return item
      }).join(' ')
    }



    const onSubmit = (value) => {
      const { content } = value
      api(
        `${isReportSource ? `/report_company/${legislative}/comments/`
          : isLicenseSource ?
            isRenewalsReporting ?
              `/renewals_reporting/${legislative}/comments/`
              : `/license_company/${legislative}/comments/`
            : '/legislative_comments/'}`,
        isReportSource
          ? {
            report_company: legislative,
            content: content.replace('\n', '<br/>\n'),
            parent: parentId,
          } : isLicenseSource
            ? {
              license_company: legislative,
              content: content.replace('\n', '<br/>\n'),
              parent: parentId,
            }
            : {
              legislative,
              content: content.replace('\n', '<br/>\n'),
              parent: parentId,
            },
        'POST',
      ).then((data) => {
        if (!data.errors) {
          store.setComment(data, true, parentId)
          reset()
        }
      })
    }
    return (
      <Container active={active} isReply={isReply}>
        <Wrap
          grey={grey}
          active={active}
          isReplied={isReplied}
          isReply={isReply}
          single={single}
          last={last}
        >
          <CommentLeft>
            <Text>
              <Text style={{ fontSize: '.857em', color: '#7D8587' }}>
                <Moment format="MMM D, YYYY, hh:mm A" withTitle>
                  {item.created}
                </Moment>
              </Text>
              {!item.is_task_comment &&
                <>
                  <CommentAuthor>
                    {item.first_name} {item.last_name}
                  </CommentAuthor>{' '}
                </>
              }
              <span
                dangerouslySetInnerHTML={{
                  __html: modifyContent(item.content),
                }}
              />
            </Text>

            {!getDataFromHistory && !disabled &&
              <ReplyButton onClick={() => setActive(!active)}>
                {!active && <img src={reply} alt="reply-icon" />}
                {active ? 'Close' : 'Reply'}
              </ReplyButton>
            }
          </CommentLeft>
          {!isReply && item.descendants?.length > 0 && (
            <ToggleButton onClick={() => setHidden(!hidden)}>
              &lt; {hidden ? 'Show' : 'Hide'} replies
            </ToggleButton>
          )}
        </Wrap>

        <ReplyBlock
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: active ? 'flex' : 'none' }}
        >
          <div style={{ width: '100%' }}>
            <To>
              Replying to {item.first_name} {item.last_name}:
            </To>
            <Input
              type={'mention'}
              control={control}
              name={'content'}
              placeholder={''}
              options={store.membersMentions}
              // defaultValue={`${item.username} `}
              style={{ minHeight: '4.75em', height: '100%' }}
              styleContainer={{ marginRight: '1em' }}
              transparent
              showSearch
            />
          </div>
          <Button
            text={'Post reply'}
            style={{ marginTop: '1.1em', marginLeft: '1em' }}
            onClick={() => setActive(false)}
          />
        </ReplyBlock>
        {!hidden && (
          <RepliesBlock>
            {item.descendants?.length > 0 &&
              item.descendants.map((currItem, index) => (
                <Comment
                  isReportSource={isReportSource}
                  isLicenseSource={isLicenseSource}
                  isRenewalsReporting={isRenewalsReporting}
                  item={currItem}
                  single={item.descendants?.length === 1}
                  last={index === item.descendants?.length - 1}
                  grey={index % 2 === 0}
                  isReply
                  parentId={parentId}
                  legislative={legislative}
                  disabled={disabled}
                />
              ))}
          </RepliesBlock>
        )}
      </Container>
    )
  },
)

export default Comment
