import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Moment from 'react-moment'
import Button from '../../../components/common/Button/button'
import Input from '../../../components/common/Input'
import { Label } from '../../../components/common/Input/input.style'
import Modal from '../../../components/common/ModalNew'
import { useStore } from '../../../mobx-store/context'
import { Cancel } from '../Right/Tasks/Modal/style'
import { inputs, liteInputs, ultraLiteInputs, reportInputs, reportLiteInputs, complianceTaskinputs, reportTaskInputs } from './inputs'
import { Checkbox } from 'antd'
import { CheckboxCover, CheckboxName } from '../Search/search.style'
import { get } from 'immutable'


const TopLine = observer(({ onSubmit, searchString, totals, isReportSource = false, reportingTask = false }) => {
  const store = useStore()
  const [visible, setVisible] = useState(false)
  const [date, setDate] = useState()
  const [isCloseDate, setIsCloseDate] = useState(false)
  const [isAssignedDate, setIsAssignedDate] = useState(false)
  const [isDateAdded, setIsDateAdded] = useState(false)
  const [isStatusDate, setIsStatusDate] = useState(false)
  const [currentDateValue, setCurrentDateValue] = useState(null)
  const [listHeight, setListHeight] = useState(170)
  const { control, handleSubmit, setValue, register, watch, reset, getValues } = useForm({
    defaultValues: {
      jurisdictions: null,
      only_past_due_items: false,
    },
  })
  const trackingMode = isReportSource ? store?.company[0]?.report_source_mode : store?.company[0]?.compliance_source_mode

  const showDateRangeModal = () => {
    setIsCloseDate(false)
    setIsAssignedDate(false)
    setIsDateAdded(false)
    setIsStatusDate(false)
    setVisible(true)
  }
  const showCloseDateRangeModal = () => {
    setIsCloseDate(true)
    setIsDateAdded(false)
    setIsAssignedDate(false)
    setIsStatusDate(false)
    setVisible(true)
  }
  const showAssignedDateRangeModal = () => {
    setIsAssignedDate(true)
    setIsDateAdded(false)
    setIsCloseDate(false)
    setIsStatusDate(false)
    setVisible(true)
  }

  const showDateAddedRangeModal = () => {
    setIsDateAdded(true)
    setIsCloseDate(false)
    setIsAssignedDate(false)
    setIsStatusDate(false)
    setVisible(true)
  }

  const showStatusDateRange = () => {
    setIsStatusDate(true)
    setIsCloseDate(false)
    setIsAssignedDate(false)
    setIsDateAdded(false)
    setVisible(true)
  }

  const onClose = () => {
    if (!date) setValue('today_proximity', null)
    setVisible(false)
  }

  const onChange = (value) => {
    if (value === 'custom') {
      return showDateRangeModal()
    } else if (value === 'closed_date_custom') {
      return showCloseDateRangeModal()
    }
    else if (value === 'assigned_date_custom') {
      return showAssignedDateRangeModal()
    }
    else if (value === 'date_added_custom') {
      return showDateAddedRangeModal()
    }
    else if (value === 'status_date_range_custom') {
      return showStatusDateRange()
    }
    if (Array.isArray(watch('status'))) {
      if (!(watch('status').includes("in_progress_deficiency") || watch('status').includes("closed_submitted"))) {
        setValue('status_date_range', null);
        setDate(null)
        getValues()
      }
    }
    setCurrentDateValue(value)
    handleSubmit(onSubmit)()
    setDate(null)
  }

  const onSave = () => {
    if (!date) return setVisible(false)
    setVisible(false)
    handleSubmit(onSubmit)()
  }

  const onCancel = () => {
    setVisible(false)
    setDate(null)
    setValue('today_proximity', currentDateValue)
  }

  useEffect(() => {
    store.getLegislativeOptions()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= 1500) {
        setListHeight(200);
      } else if (width >= 1380) {
        setListHeight(170);
      } else {
        setListHeight(100);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    reset({
      today_proximity: null,
      date_added: null,
      jurisdictions: null,
      close_date: null,
      task_assigned: null,
      only_past_due_items: false,
      report_due_date: null,
      status_date_range: null,
      assignee: null,
      status: null,
      priority: null,
      legislative: null,
      report_type: null,
      report_status: null,
      topic: null,
      line_of_business: null,
      task_assigned_to: null,
      is_starred: null,
      compliance_owner: null,
      reporting_entity: null,
      ties_to_license: null,
      report_owner: null,
    })
    setDate(null)
  }, [reportingTask, reset])



  useEffect(() => {
    store.loadMembers(null, isReportSource,)
  }, [])

  const columns = trackingMode === 'compliance_source_lite' ? 6 : 5

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        padding: '0 2.313em',
        gap: 15,
        marginTop: 15,
      }}
    >
      {visible && (
        <Modal onClose={onClose}>
          <Label style={{ marginBottom: 15 }}>Select Date Range</Label>
          <Input
            type='date_range'
            control={control}
            name={isCloseDate ? 'date_range1' : isAssignedDate ? 'date_range2' : isDateAdded ? 'date_range3' : isStatusDate ? 'date_range4' : 'date_range'}
            handleChange={(value) => setDate(value)}
            isSearchOpened
            noLimit
            reset={() => {
              setDate(null)
              // setValue('date_added', null)
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 15,
            }}
          >
            <Cancel onClick={onCancel}>Cancel</Cancel>
            <Button text='Save' onClick={onSave} />
          </div>
        </Modal>
      )}
      {isReportSource
        ? reportingTask ?
          reportTaskInputs(store, totals).map(
            (item) =>
              !item.isHidden && (
                <div>
                  <Input
                    key={item.key}
                    control={control}
                    type='multiSelect'
                    smallLabel
                    {...item}
                    options={item.options}
                    handleChange={onChange}
                    getPopupContainer={true}
                    listHeight={listHeight}
                  />
                  {item.name === 'today_proximity' && date && !isCloseDate && !isAssignedDate && !isDateAdded && !isStatusDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                  {item.name === 'close_date' && date && isCloseDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                  {item.name === 'task_assigned' && date && isAssignedDate && !isCloseDate && !isDateAdded && !isStatusDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                </div>
              )
          )
          : trackingMode === 'report_source_lite'
            ? reportLiteInputs(store, totals).map(
              (item) =>
                !item.isHidden && (
                  <div>
                    <Input
                      key={item.key}
                      control={control}
                      type='multiSelect'
                      listHeight={listHeight}
                      getPopupContainer={true}
                      smallLabel
                      {...item}
                      options={item.options}
                      handleChange={onChange}
                    />
                    {item.name === 'report_due_date' && date && !isCloseDate && !isDateAdded && !isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                    {item.name === 'close_date' && date && isCloseDate && !isDateAdded && !isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                  </div>
                )
            )
            : reportInputs(store, searchString, totals).map(
              (item) =>
                !item.isHidden && (
                  <div>
                    <Input
                      key={item.key}
                      control={control}
                      listHeight={listHeight}
                      disabled={item.disabled}
                      getPopupContainer={true}
                      type='multiSelect'
                      smallLabel
                      {...item}
                      options={item.options}
                      handleChange={onChange}
                    />
                    {item.name === 'report_due_date' && date && !isCloseDate && !isDateAdded && !isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                    {item.name === 'close_date' && date && isCloseDate && !isDateAdded && !isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                    {item.name === 'status_date_range' && date && !isCloseDate && !isDateAdded && isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                  </div>
                )
            )
        : reportingTask
          ? complianceTaskinputs(store, totals).map(
            (item) =>
              !item.isHidden && (
                <div>
                  <Input
                    key={item.key}
                    control={control}
                    listHeight={listHeight}
                    getPopupContainer={true}
                    type='multiSelect'
                    smallLabel
                    {...item}
                    options={item.options}
                    handleChange={onChange}
                  />
                  {item.name === 'today_proximity' && date && !isCloseDate && !isAssignedDate && !isDateAdded && !isStatusDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                  {item.name === 'date_added' && date && !isCloseDate && !isAssignedDate && isDateAdded && !isStatusDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                  {item.name === 'close_date' && date && isCloseDate && !isStatusDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                  {item.name === 'task_assigned' && date && isAssignedDate && !isCloseDate && !isDateAdded && !isStatusDate && (
                    <div
                      style={{
                        fontFamily: 'var(--light)',
                        fontSize: 12,
                        color: 'var(--text)',
                        marginTop: 5,
                        textAlign: 'center',
                      }}
                    >
                      <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                      <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                    </div>
                  )}
                </div>
              )
          )
          : trackingMode === 'compliance_source_ultra_lite'
            ? ultraLiteInputs(store, totals).map(
              (item) =>
                !item.isHidden && (
                  <div>
                    <Input
                      key={item.key}
                      control={control}
                      listHeight={listHeight}
                      getPopupContainer={true}
                      type='multiSelect'
                      smallLabel
                      {...item}
                      options={item.options}
                      handleChange={onChange}
                    />
                    {item.name === 'today_proximity' && date && !isCloseDate && !isDateAdded && !isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                    {item.name === 'close_date' && date && isCloseDate && !isDateAdded && !isStatusDate && (
                      <div
                        style={{
                          fontFamily: 'var(--light)',
                          fontSize: 12,
                          color: 'var(--text)',
                          marginTop: 5,
                          textAlign: 'center',
                        }}
                      >
                        <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                        <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                      </div>
                    )}
                  </div>
                )
            ) : trackingMode === 'compliance_source_lite'
              ? liteInputs(store, totals).map(
                (item) =>
                  !item.isHidden && (
                    <div>
                      <Input
                        key={item.key}
                        control={control}
                        listHeight={listHeight}
                        getPopupContainer={true}
                        type='multiSelect'
                        smallLabel
                        {...item}
                        options={item.options}
                        handleChange={onChange}
                      />
                      {item.name === 'today_proximity' && date && !isCloseDate && !isDateAdded && !isStatusDate && (
                        <div
                          style={{
                            fontFamily: 'var(--light)',
                            fontSize: 12,
                            color: 'var(--text)',
                            marginTop: 5,
                            textAlign: 'center',
                          }}
                        >
                          <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                          <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                        </div>
                      )}
                      {item.name === 'close_date' && date && isCloseDate && !isDateAdded && !isStatusDate && (
                        <div
                          style={{
                            fontFamily: 'var(--light)',
                            fontSize: 12,
                            color: 'var(--text)',
                            marginTop: 5,
                            textAlign: 'center',
                          }}
                        >
                          <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                          <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                        </div>
                      )}
                    </div>
                  )
              )
              : inputs(store, totals).map(
                (item) =>
                  !item.isHidden && (
                    <div>
                      <Input
                        key={item.key}
                        control={control}
                        listHeight={listHeight}
                        getPopupContainer={true}
                        type='multiSelect'
                        smallLabel
                        {...item}
                        options={item.options}
                        handleChange={onChange}
                      />
                      {item.name === 'today_proximity' && date && !isCloseDate && !isDateAdded && !isStatusDate && (
                        <div
                          style={{
                            fontFamily: 'var(--light)',
                            fontSize: 12,
                            color: 'var(--text)',
                            marginTop: 5,
                            textAlign: 'center',
                          }}
                        >
                          <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                          <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                        </div>
                      )}
                      {item.name === 'close_date' && date && isCloseDate && !isDateAdded && !isStatusDate && (
                        <div
                          style={{
                            fontFamily: 'var(--light)',
                            fontSize: 12,
                            color: 'var(--text)',
                            marginTop: 5,
                            textAlign: 'center',
                          }}
                        >
                          <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                          <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                        </div>
                      )}
                      {item.name === 'date_added' && date && !isCloseDate && isDateAdded && !isStatusDate && (
                        <div
                          style={{
                            fontFamily: 'var(--light)',
                            fontSize: 12,
                            color: 'var(--text)',
                            marginTop: 5,
                            textAlign: 'center',
                          }}
                        >
                          <Moment format='MM/DD/YYYY'>{date.split('±')[0]}</Moment> -
                          <Moment format='MM/DD/YYYY'>{date.split('±')[1]}</Moment>
                        </div>
                      )}
                    </div>
                  )
              )
      }
      {
        trackingMode !== 'compliance_source_ultra_lite' &&
        <CheckboxCover style={
          trackingMode === 'compliance_source_lite'
            ? { marginLeft: 0, marginBottom: 0, marginTop: 0 }
            : { marginLeft: 10, marginBottom: 0, marginTop: 10 }
        }>
          <Checkbox
            {...register("only_past_due_items")}
            checked={watch('only_past_due_items')}
            onChange={(e) => {
              setValue('only_past_due_items', e.target.checked);
              handleSubmit(onSubmit)();
            }}
            name={"past_due_items"}
            style={{ marginRight: 5 }}
          />
          <CheckboxName style={{
            fontFamily: 'var(--medium)',
            opacity: 1,
            fontSize: 12,
            lineHeight: 0.95
          }}>
            {isReportSource ? 'Show only past due report items' : 'Show only past due compliance items'}
          </CheckboxName>
        </CheckboxCover>
      }

    </div>
  )
})

export default TopLine
