import React from 'react'
import { Mentions } from 'antd'

const InputMentions = ({
  onChange,
  handleChange,
  defaultValue,
  value,
  options,
  placeholder,
}) => {
  return <Mentions
    style={{ width: '100%' }}
    placeholder={placeholder}
    onChange={(value) => {
      onChange(value)
      handleChange && handleChange(value)
    }}
    autoSize
    defaultValue={defaultValue ? '@' + defaultValue : ''}
    value={value || ''}
  >
    {options}
  </Mentions>
}

export default InputMentions
