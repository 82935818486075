import React from 'react';
import Modal from '../../../../components/common/ModalNew/index'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
const CloseTasksModal = ({ onClose, onSubmit }) => {
  return (
    <Modal onClose={onClose}>
      <Label>Please close all tasks before moving the license.</Label>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
        <Button text='Confirm' onClick={() => onClose()} />
      </div>
    </Modal>
  )
}

export default CloseTasksModal
