import { useState, useEffect } from 'react';
import Button from '../../../../components/common/Button/button';
import { BlockWrap, Row, Label, Block, Text, HistoryTip } from '../right.style';
import expandIcon from '../../../../assets/images/expand.svg'
import hideIcon from '../../../../assets/images/hide.svg'
import { showHeldMaterialsTabs, heldAdminActionsTabs } from '../tabs'
import Tabs from '../../../../components/common/Tabs/tabs'
import { api } from '../../../../api';
import { message } from 'antd';
import { moment } from 'moment'
import { downloadDoc } from '../../../../api';



const HeldHistoryBlock = ({ getCurrentLegislative, id, data, heldHistory = {} }) => {
  const [loading, setLoading] = useState(true);
  const [showDisabledAssessmentBlock, setShowDisabledAssessmentBlock] = useState(false);
  const [activeAdminActionsTab, setActiveAdminActionsTab] = useState('admin_actions');
  // const [heldHistory, setHeldHistory] = useState(null);

  const toggleAssessmentBlock = () => {
    setShowDisabledAssessmentBlock(!showDisabledAssessmentBlock);
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  // const getHeldHistory = () => {
  //   setLoading(true)
  //   api(`/license_company/${id}/held_history/`, {}, 'GET').then(data => {
  //     if (data.errors) {
  //       return message.error('Error while fetching held history')
  //     }
  //     setHeldHistory(data)
  //     setLoading(false)
  //   })
  // }

  // useEffect(() => {
  //   getHeldHistory()
  // }, [])

  const heldReferences = [
    {
      key: `held1`,
      title: 'License Number',
      description: heldHistory?.license_number || 'See Notes',
    },
    {
      key: `held2`,
      title: 'Date License Obtained',
      description: heldHistory?.date_license_obtained || 'See Notes',
    },
    {
      key: `held3`,
      title: 'Expiration Date',
      description: heldHistory?.is_perpetual_license ? 'Perpetual' : heldHistory?.expiration_date || 'See Notes',
    },
    {
      key: `held6`,
      title: 'Renewal Date',
      description: heldHistory?.renewal_date || 'See Notes',
    },
    {
      key: `held4`,
      title: 'View License Certificates:',
      description: heldHistory?.held_certificates
        ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          {heldHistory?.held_certificates.map((item, index) => (
            <div
              onClick={() => downloadDoc(`license_company/${heldHistory?.id}/certificates/${item.id}/`, item.file_name, false, true, false, null, null, true)}
              style={{
                fontSize: 12,
                fontFamily: 'var(--regular)',
                color: 'var(--orange)', textDecoration: 'underline',
                cursor: 'pointer'
              }} key={index}>
              {item.file_name}
            </div>
          ))}
        </div>
        : 'See Notes',
    }
  ]

  return (
    <BlockWrap>
      {!isEmpty(heldHistory) &&
        <>
          <Button
            text={showDisabledAssessmentBlock ? 'Hide Previous “Held” Details' : 'Show Previous “Held” Details'}
            style={{
              fontSize: 14,
              backgroundColor: '#fff',
              border: 'none',
              color: '#455154',
              padding: '0',
            }}
            onClick={toggleAssessmentBlock}
            icon={showDisabledAssessmentBlock ? hideIcon : expandIcon}
          />
          {showDisabledAssessmentBlock &&
            <>
              <HistoryTip> Information in this section is historical and not editable</HistoryTip>
              <Row style={{ marginTop: '1em' }}>
                {heldReferences?.map(
                  ({ key, title, description, hide }) =>
                    !hide && (
                      <Block key={key} style={{ maxWidth: 250 }}>
                        <Label>{title}</Label>
                        {title === 'View License Certificates:'
                          ? description
                          : <Text dangerouslySetInnerHTML={{ __html: description }} />
                        }
                      </Block>
                    ))}
              </Row>
              <BlockWrap>
                <Tabs
                  Reporting={false}
                  tabs={heldAdminActionsTabs}
                  onClick={(key) => {
                    // getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource, isLicenseSource)
                    setActiveAdminActionsTab(key)

                  }}
                  active={activeAdminActionsTab}
                />
                {showHeldMaterialsTabs({
                  data, historyMode: true, heldHistory
                })[activeAdminActionsTab].render()
                }
              </BlockWrap>
            </>
          }
        </>
      }
    </BlockWrap>
  );
};

export default HeldHistoryBlock;