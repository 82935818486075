import React from 'react'
import { Block, InfoBlock, DisclaimerBlock, EmptyBlock } from '../../right.style'
import { highlightSearchedWords } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'

const ReportTimePeriod = ({ isTips, isMyTasks, noticeOfChange }) => {
  const store = useStore()

  const data = isTips
    ? store?.currentLegislative?.report?.tips
    : noticeOfChange
      ? store?.currentLegislative?.report?.notice_of_change
      : store?.currentLegislative?.report?.reporting_time_period

  if (!data) return <EmptyBlock>Not Specified.</EmptyBlock>
  return (
    <Block
      style={{
        marginTop: '1em',
        paddingBottom: '1em',
        minHeight: 120,
      }}
      bordered>
      {data && <InfoBlock dangerouslySetInnerHTML={{
        __html: isMyTasks
          ? highlightSearchedWords(data, store?.searchableObjectTasks?.search)
          : highlightSearchedWords(data, store?.searchableObject?.search)
      }}></InfoBlock>}
      {isTips && store?.currentLegislative?.report?.tips &&
        <DisclaimerBlock>
          This information is informational only and is not a substitute for an attorney or law firm.
          Determining the applicable line of business and other applicability for a report/requirement often is subject to interpretation.
          PBMSource has made efforts to assess the appropriate lines of business and applicability of these requirements
          (which are subject to change as additional information becomes available).
          This information is provided as guidance only, and you are encouraged to do your own research and assessments and/or contact an attorney or the applicable government agency.
          If you question or conclude a different line of business(es) or applicability,
          please let us know so that we can consider updates to this information. Contact us at 888-684-1391 or <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a>.
        </DisclaimerBlock>}
    </Block>
  )
}

export default ReportTimePeriod
