import styled from 'styled-components';

export const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  border: 1px solid var(--grey);
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Input = styled.input`
  width: 90%;
  height: 25px;
  padding: 5px;
  border: none;
  font-family: var(--regular);
  color: var(--text);
   background-color: transparent;
  &:focus {
    outline: none; 
`;