import { Select } from 'antd'
import React from 'react'
import { checkRole, getDate } from '../../../helpers/helpers'
import { statuses } from '../Table/table'

export const infoInputs = (store, data) => {
  return [
    {
      key: 0,
      label: 'Compliance Item Owner',
      name: 'assignee',
      placeholder: 'Unassigned',
      role: ['admin', 'compliance_owner', 'owner'],
      value: store.getMember(data?.assignee),
      customOptions: store.members
        .filter((item) => {
          return (
            (item.is_active || item.id === data?.assignee) &&
            item.have_access_to_tracking &&
            checkRole(
              [item.member_type],
              ['admin', 'compliance_owner', 'owner']
            )
          )
        })
        .map((item) => (
          <Select.Option value={item.id} key={item.id}>
            {item.first_name + ' ' + item.last_name}
          </Select.Option>
        )),
    },
    {
      key: 1,
      label: 'Current Status',
      name: 'status',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner'],
      value: statuses[data.status]?.[0],
      options: store.statuses,
      selectVars: { value: 0, name: 1 },
    },
    {
      key: 2,
      label: 'Internal Due Date ',
      name: 'primary_due_date',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner'],
      type: 'date',
      value: getDate(data.primary_due_date, '-'),
    },
  ]
}

export const reportInfoInputs = (store, data) => {
  return [
    {
      key: 0,
      label: 'Report Item Owner',
      name: 'assignee',
      placeholder: 'Unassigned',
      role: ['admin', 'compliance_owner', 'owner', 'report_owner', 'license_owner'],
      value: store.getMember(data?.assignee),
      customOptions: store.members
        .filter((item) => {
          return (
            (item.is_active || item.id === data?.assignee) &&
            item.have_access_to_report_source &&
            checkRole(
              [item.report_source_role],
              ['admin', 'report_owner', 'owner']
            )
          )
        })
        .map((item) => (
          <Select.Option value={item.id} key={item.id}>
            {item.first_name + ' ' + item.last_name}
          </Select.Option>
        )),
    },
    {
      key: 1,
      label: 'Current Status',
      name: 'status',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner', 'report_owner', 'license_owner'],
      value: statuses[data.status]?.[0],
      options: store.report_source_statuses?.filter((item) => item[0] !== 'monitor'),
      selectVars: { value: 0, name: 1 },
    },
    {
      key: 2,
      label: 'Internal Due Date ',
      name: 'report_due_date',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner', 'report_owner', 'license_owner'],
      type: 'date',
      value: getDate(data?.report?.report_due_date, '-'),
    },
  ]
}

export const lsInfoInputs = (store, data, isHeldHistory = false, isIaHistory = false) => {
  return [
    {
      key: 0,
      label: data.type && !isIaHistory
        ? data.type === 'renewal'
          ? 'Renewal Item Owner'
          : 'Reporting Item Owner'
        : (data.status === 'na' || data.status === 'monitor' || data.status === 'not_assessed') && !isHeldHistory && !isIaHistory
          ? 'Assessment Item Owner'
          : 'License Item Owner',
      name: data?.status === 'needed' || isIaHistory ? 'initial_application_assignee' : 'assignee',
      placeholder: 'Unassigned',
      role: ['admin', 'license_owner', 'owner'],
      value: data?.status === 'needed' || isIaHistory ? store.getMember(data?.initial_application_assignee) : store.getMember(data?.assignee),
      disabled: data.status === 'held' || isHeldHistory || isIaHistory,
      customOptions: store.members
        .filter((item) => {
          return (
            (item.is_active || item.id === data?.assignee) &&
            item.have_access_to_license_source &&
            checkRole(
              [item.license_source_role],
              ['admin', 'license_owner',]
            )
          )
        })
        .map((item) => (
          <Select.Option value={item.id} key={item.id}>
            {item.first_name + ' ' + item.last_name}
          </Select.Option>
        )),
    },
    {
      key: 1,
      label: data.type && !isIaHistory
        ? data.type === 'renewal'
          ? 'Renewal Status'
          : 'Reporting Status'
        : data.status == 'needed' || isHeldHistory || isIaHistory ? 'License Status' : 'Assessment Status',
      name: data.status === 'needed' || isIaHistory ? 'initial_application_status' : 'status',
      placeholder: 'Select Status',
      role: ['admin', 'compliance_owner', 'owner', 'license_owner'],
      value: isIaHistory ? statuses[data.initial_application_status]?.[0] : statuses[data.status]?.[0],
      options: (data.type === 'renewal' && !isIaHistory) ? store.license_source_renewals_statuses : (data.status === 'needed' || isIaHistory) ? store.initial_application_statuses : store.license_source_statuses,
      selectVars: { value: 0, name: 1 },
      disabled: data.status === 'held' || isHeldHistory,
    },
    {
      key: 2,
      label: 'Internal Due Date ',
      name: data?.status === 'needed' || isIaHistory ? 'initial_application_internal_due_date' : 'internal_due_date',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner', 'license_owner'],
      type: 'date',
      disabled: data.status === 'held' || isHeldHistory || isIaHistory,
      value: (data?.status === 'needed' || isIaHistory) ? getDate(data?.initial_application_internal_due_date, '-') : getDate(data?.report?.report_due_date, '-'),
    },
  ]
}

