import React, { useEffect, useState } from 'react'
import { Block, DocumentsPlaceholder, Label } from './right.style'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../mobx-store/context'
import { Spin } from 'antd'
import CustomPagination from '../../../components/common/Pagination/pagination'
import Document from './document'
import Validation from '../../../components/common/Validation/validation'
import { DownloadDocsContainer, DownloadLink } from './right.style'
import DownloadIcon from '../../../assets/images/download_blue_icon.svg'
import { downloadAllDocs } from '../../../api'

const Documents = observer(({
  reportId,
  legislative,
  getTasks,
  isReportSource = false,
  isLicenseSource = false,
  isRenewalsReporting,
  status,
  showDelete = true,
  isNeedHistory = false
}) => {
  const store = useStore()
  const [page, setPage] = useState(1)
  const [successDeleted, setSuccessDeleted] = useState(null)
  const [loading, setLoading] = useState(true)
  const documents = isNeedHistory ? store?.needHistoryDocuments?.results : store.documents.results

  const currentLegislative = store?.currentLegislative;

  const getName = () => {
    if (isReportSource) {
      return currentLegislative?.report?.report_title;
    }

    if (isLicenseSource) {
      if (isRenewalsReporting) {
        return currentLegislative?.type === 'report'
          ? currentLegislative?.report?.report_title
          : currentLegislative?.license?.license_title;
      }
      return currentLegislative?.license?.license_title;
    }

    return currentLegislative?.reference_number;
  };

  useEffect(() => {
    if (legislative) store.loadDocuments(legislative, '1', status, isReportSource, isLicenseSource, isRenewalsReporting, isNeedHistory)
    setLoading(false)
  }, [legislative, store?.currentLegislative?.report?.id, status])

  useEffect(() => {
    setTimeout(() => {
      setSuccessDeleted(null)
    }, 5000)
  }, [successDeleted])

  if (loading) return <Spin />
  if (isNeedHistory ? !store?.needHistoryDocuments?.results : !store.documents.results) return null

  return (
    <Block style={{ marginTop: '1em', marginBottom: '6em', }} bordered>
      <DownloadDocsContainer>
        <Label>Documents</Label>
        {documents.length > 0 && (
          <DownloadLink onClick={() => downloadAllDocs(legislative, status, getName(), isReportSource, isLicenseSource, isRenewalsReporting, isNeedHistory, currentLegislative.id)}>
            <img src={DownloadIcon} alt="icon" style={{ marginRight: 5 }} />
            Download All to .Zip
          </DownloadLink>
        )}
      </DownloadDocsContainer>
      {documents.length <= 0 && (
        <DocumentsPlaceholder>
          There are no documents associated with this item yet.
        </DocumentsPlaceholder>
      )}
      {documents.map((item, index) => (
        <Document
          item={item}
          index={index}
          key={item?.id}
          groupStatus={status}
          onDeleted={(file) => {
            setSuccessDeleted(file)
            getTasks()
          }}
          isNeedHistory={isNeedHistory}
          legislative={legislative}
          isReportSource={isReportSource}
          isLicenseSource={isLicenseSource}
          isRenewalsReporting={isRenewalsReporting}
          reportId={reportId}
          showDelete={showDelete}
        />
      ))}
      {successDeleted && (
        <Validation
          noLineThrough
          text={<span>{successDeleted} was deleted</span>}
        />
      )}

      <CustomPagination
        total={store.documents.count}
        pageSize={'10'}
        current={page}
        onChange={(page) => {
          store.loadDocuments(legislative, page, status, isReportSource, isLicenseSource, isRenewalsReporting, isNeedHistory)
          setPage(page)
        }}
      />
    </Block>
  )
})

export default Documents
