import styled from 'styled-components'
import {
  Wrap as ButtonWrap,
  WrapWhite as ButtonWrapWhite,
} from '../../../components/common/Button/button.style'

export const Wrap = styled('div')`
  padding-left: 2em;
  padding-top: 50px; 
  padding-bottom: 200px;
  padding-right: 2em;
  // margin-bottom: 50px;
  max-height: 74vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: var(--grey);
    border-radius: 5px;
    width: 8px;
  }

  @media (max-width: 1350px) {
    padding-left: 0;
    padding-top: 2em;
    padding-right: 2em;
  }
  @media (max-height: 1200px) {
    max-height: 76vh;
  }
  @media (max-height: 1050px) {
    max-height: 73vh;
  }
  @media (max-height: 915px) {
    max-height: 66vh;
  }
  @media (max-height: 880px) {
    max-height: 69vh;
  }
  @media (max-height: 800px) {
    max-height: 65vh;
  }
  @media (max-height: 750px) {
    max-height: 65vh;
  }

`

export const RowWrap = styled('div')`
display: flex;
align-items: center;
justify-content: space-between;
`
export const BlockWrap = styled('div')`
  border: 1px solid #c4cacc;
  padding: 10px;
  margin-top: 20px;
`

export const FirstLetterUppercase = styled('div')`
  &::first-letter {
    text-transform: uppercase;
  }
`

export const Reference = styled('div')`
  font-family: var(--semiBold);
  font-size: 1.5em;
  color: var(--blue);
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => (props.showLinkedReports ? '0px' : '1em')};
  border-bottom: 1px solid #c4cacc;
  justify-content: space-between;


  ${ButtonWrapWhite} {
    height: 34px;
    font-size: 14px;
    padding: 0 15px;

    img {
      margin-left: 10px;
      margin-right: 0;
      height: 50%;
    }
  }
`
export const Circle = styled('span')`
    width: 1em;
    height: 1em;
    border-radius: 100%;
    background-color: var(--yellow);
`

export const Label = styled('div')`
  font-family: var(--semiBold);
  font-size: 0.875em;
  color: var(--text);
  margin-bottom: 0.5em;
`

export const Text = styled('div')`
  font-family: var(--regular);
  font-size: 0.875em;
  color: var(--text);
  letter-spacing: 0.3px;
  overflow-wrap: break-word; /* Современный стандарт */
  word-wrap: break-word; /* Для совместимости со старыми браузерами */
  word-break: break-word;
  
  mark {
    background-color: var(--yellow);
  }

  pre {
    font-family: var(--regular);
  }

  b {
    font-family: var(--bold);
  }

  p {
    margin: 0;
  }

  a {
    color: var(--green);
    text-decoration: underline;
  }

  .file-deleted {
    text-decoration: underline;
    color: #c4c4c4;
  }

  mention {
    color: var(--orange);
    font-family: var(--bold);
  }
    
`


export const InputsRow = styled('div')`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) 11em;
  gap: 1.438em;
  margin-top: 2em;
  align-items: end;
`
export const PriorsRow = styled('div')`
  background-color: #f4f6f6;
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 0px;
  margin-top: 10px;
`

export const Activity = styled('div')`
  display: grid;
  grid-template-columns: 85% 15%;
  gap: 0.5em;
  margin-top: 0;
  background-color: #f4f6fa;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 1em 1em;
`

export const ActivityName = styled('span')`
  font-family: var(--semiBold);
  color: var(--text);
`

export const ActivityItem = styled('span')`
  color: var(--green);
  text-decoration: underline;
  font-family: var(--medium);
`

export const Block = styled('div')`
  border-bottom: 1px solid ${(props) => (props.bordered ? '#C4CACC' : '#fff')};
`
export const InternalDueDateBlock = styled('div')`
 background: var(--rose);
 font-family: var(--regular);
 color: var(--text);
 font-size: 14px;
 padding: 10px 15px;
 margin: 10px 0;
 strong {
    font-family: var(--semiBold);
 }
`

export const DocumentWrap = styled('div')`
  font-family: var(--open-sans-semiBold);
  font-size: 0.875em;
  color: var(--text);
  padding: 1em;
  background-color: ${(props) => (props.grey ? '#f4f6f6' : '#fff')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DocumentRight = styled('div')`
  display: flex;

  div,
  a {
    display: flex;
    align-items: center;
    color: var(--text);
    font-style: italic;
    cursor: pointer;
    font-family: var(--regular);
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
    

    img {
      margin-right: 0.5em;
      height: 1em;
    }
  }
`

export const NotChoosen = styled('div')`
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--light);
  color: #000;
  font-size: 2em;
  text-align: center;
  line-height: 1.188em;
  margin-top: 40px;

  img {
    height: 337px;
    // margin-bottom: 20px;
  }
`
export const NotChoosenDetermination = styled('div')`
width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--light);
  color: #B5B9BB;
  font-size: 28px;
  text-align: center;
  line-height: 1.188em;
  margin-top: 140px;
`
export const Row = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const DocumentsPlaceholder = styled('div')`
  color: var(--text);
  font-size: 1em;
  font-family: var(--regular);
  opacity: 0.6;
`

export const Read = styled(ButtonWrap)``

export const LineOfBusiness = styled('div')`
  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    margin-left: 7px;
  }
`

export const DueDate = styled('div')`
  position: relative;
  width: 361px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  padding: 50px;
  border-radius: 5px;
`

export const Inputs = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 15px;
`
export const InfoBlock = styled('div')`
  font-size: 14px;
  font-family: var(--regular);
  margin-top: 1em;
  color: var(--text);
`

export const DisclaimerBlock = styled('div')`
  font-size: 11px;
  font-family: var(--regular);
  color: var(--text);
  margin-top: 1em;
  line-height: 1.2;
  letter-spacing: 0.1px;
`
export const EmptyBlock = styled('div')`
  margin-top: 1em;
  font-size: 14px;
  font-family: var(--regular);
  color: var(--text);
  height: 120px; 
  padding-bottom: 16px;
  border-bottom: 1px solid #C4CACC;
`
export const HistoryTip = styled('div')`
  width: 100%;
  background: #EDF7F7;
  font-family: var(--regular);
  font-size: 12px;
  padding: 5px 10px;
`

export const DocumentRenameContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: space-between;
  justify-content: center;
  width: 450px;
  border: 1px solid var(--grey);
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const RenameInput = styled.input`
  width: 90%;
  height: 25px;
  padding: 5px;
  border: none;
  margin-right: 10px;
  font-family: var(--regular);
  color: var(--text);
  background-color: transparent;
  &:focus {
    outline: none; 
  }
`;

export const DownloadDocsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const DownloadLink = styled.div`
  display: flex;
  align-items: center;
  color: var(--blue);
  font-family: var(--bold);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export const DocumentLink = styled.a`
  color: var(--text);
  font-family: var(--semiBold);
  font-size: 14px;
  cursor: pointer;
   &:hover {
        text-decoration: underline;
        color: var(--text);
        font-family: var(--semiBold);
      }
`;