import styled from "styled-components";

export const TabButton = styled.button`
  padding: 4px 7px;
  border-radius: 7px;
  border: 1px solid #d9d9d9;
  font-family: ${(props) => props.isSelected ? 'var(--bold)' : 'var(--regular)'};
  background-color: ${(props) => (props.isSelected ? "#4E74A9" : "#99A2B4")};
  color: white;  
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;


  &:hover {
    border: 1px solid ${props => (props.isSelected ? "#4E74A9" : "#99A2B4")};
  }
  ${(props) =>
    props.enableMedia &&
    `
    @media (max-width: 1615px) {
      font-size: 10px;
    }
    @media (max-width: 1378px) {
      font-size: 9px;
    }
    @media (max-width: 1349px) {
      font-size: 12px;
    }
    @media (max-width: 725px) {
      font-size: 9px;
    }
  `}
`;

