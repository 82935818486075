import { get, toJS } from 'mobx'
import { Observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import flag from '../../../assets/images/flag.svg'
import {
  getDate,
  getDescription,
  getStatusColor,
  getReportStatusColor,
  getReportMyTaskStatusColor,
  getTrackingMyTaskStatusColor,
  getTaskReportStatusColor,
  getLicenseStatusColor,
  getSecondElement,
} from '../../../helpers/helpers'
import { getString } from '../Right/right'
import { statuses, reportMyTaskStatuses, trackingMyTaskStatuses, statusesTaskReport, renewalsLicensesMyTaskStatuses } from './table'
import {
  FirstLetterUppercase,
  Render,
  Status,
  TableHeaderTitle,
} from './table.style'
import moment from 'moment'
import image from '../../../assets/images/warning_small.svg'
import { Tag } from 'antd'

const getDataIndex = ({ field, isMyTasks, activeTab }) => {
  if (isMyTasks) return ['legislative', field]
  return field
}

export const getRecord = ({ record, isMyTasks, activeTab, isReportSource = false, isLicenseSource = false, }) =>
  isLicenseSource ? record
    : !isReportSource && isMyTasks ? record.legislative : record

const columnsComplianceDate = (params) => ({
  title: <TableHeaderTitle name={'title'}>Compliance Date</TableHeaderTitle>,
  dataIndex: 'compliance_date',
  key: 'compliance_date',
  align: 'left',
  sorter: true,
  width: 200,
  sortOrder:
    (params.sorter?.columnKey === 'compliance_date' && params.sorter.order) ||
    null,
  render: (text) => <Render name={'compliance-date-cell'}>{getDate(text)}</Render>,
  ...(params.compliance_date || {}),
})

const columnsDueDate = (params) => ({
  title: <TableHeaderTitle name={'title'}>Due Date</TableHeaderTitle>,
  dataIndex: 'task_due_date',
  key: 'task_due_date',
  align: 'left',
  sorter: true,
  width: 200,
  sortOrder:
    (params.sorter?.columnKey === 'task_due_date' && params.sorter.order) ||
    null,
  render: (text) => <Render name={'task-due-date-cell'}>{getDate(text)}</Render>,
  ...(params.task_due_date || {}),
})

const columnsAdoptedDate = (params) => ({
  title: <TableHeaderTitle name={'title'}>Adopted Date</TableHeaderTitle>,
  dataIndex: 'adopted_date',
  key: 'adopted_date',
  align: 'left',
  sorter: true,
  width: 200,
  sortOrder:
    (params.sorter?.columnKey === 'adopted_date' && params.sorter.order) ||
    null,
  render: (text, record) => <Render name={'adopted-date-cell'}>{getDate(text)}</Render>,
  ...(params.adopted_date || {}),
})

const columnsAdoptedDateUltraLite = (params) => ({
  title: <TableHeaderTitle name={'title'}>Adopted Date</TableHeaderTitle>,
  dataIndex: 'adopted_date',
  key: 'adopted_date',
  align: 'center',
  sorter: true,
  width: 200,
  sortOrder:
    (params.sorter?.columnKey === 'adopted_date' && params.sorter.order) ||
    null,
  render: (text, record) => <Render name={'adopted_date_cell'}>{getDate(record.legislatives[0].adopted_date)}</Render>,
  ...(params.adopted_date || {}),
})


const columnsWithRefNumber = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Reference #</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'reference_number', ...params }),
    key: 'reference_number',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) =>
      <Render
        name={'reference-number-cell'}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        }}>
        {text}
        {!params.isMyTasks &&
          <Status bg={record.status === 'closed' ? 'var(--grey)' : 'var(--blue)'}>
            <div />
          </Status>}
      </Render>,
    ...(params.reference_number || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Adopted Date</TableHeaderTitle>,
    dataIndex: 'adopted_date',
    key: 'adopted_date',
    align: 'center',
    sorter: true,
    width: 100,
    sortOrder:
      (params.sorter?.columnKey === 'adopted_date' && params.sorter.order) ||
      null,
    render: (text) => <Render name={'adopted-date-cell'}>{getDate(text)}</Render>,
    ...(params.adopted_date || {}),
  },
]

export const licenseColumnsCommon = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: '10%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text, 'code')}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Type</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'license_type', ...params }),
    key: 'license_type',
    width: '20%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_type' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.license_pending_types, text)}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>License Title</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'license_title', ...params }),
    key: 'license_title',
    width: '55%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_title' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{text}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    width: '15%',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
    render: (text, record) => {
      return (
        <Observer>
          {() => (
            <Render
              name={'status-cell'}
              style={{
                display: 'grid',
                gridTemplateColumns: '10px 1fr',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Status bg={getLicenseStatusColor(text)}>
                <div />
              </Status>
              {params.activeTab === 'ia_all' || params.activeTab === 'ia_unassigned' || params.activeTab === 'ia_in_progress' || params.activeTab === 'ia_in_progress_ready_for_final_review' || params.activeTab === 'ia_in_progress_submitted_to_govt' || params.activeTab === 'ia_in_progress_deficiency'
                ? getSecondElement(text, params?.store?.initial_application_statuses)
                : getSecondElement(text, params?.store?.license_source_statuses)
              }
            </Render>
          )}
        </Observer>
      )
    },
    ...(params.status || {}),
  },
  {
    title: (
      <TableHeaderTitle name={'title'}>
        <img src={flag} alt='flag-icon' />
      </TableHeaderTitle>
    ),
    dataIndex: 'is_priority',
    key: 'is_priority',
    align: 'left',
    width: '5%',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'is_priority' && params.sorter.order) || null,
    render: (text) => (
      <Render name={'is-starred-cell'} style={{ display: 'flex', justifyContent: 'center' }}>
        {text && <img src={flag} alt='flag-icon' />}
      </Render>
    ),
    ...(params.is_priority || {}),
  }
]

export const licensecolumnsHeld = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: '10%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text, 'code')}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Type</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'license_type', ...params }),
    key: 'license_type',
    width: '20%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_type' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.license_pending_types, text)}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>License Title</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'license_title', ...params }),
    key: 'license_title',
    width: '55%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_title' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{text}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>License #</TableHeaderTitle>,
    dataIndex: 'license_number',
    key: 'license_number',
    width: '15%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_number' && params.sorter.order) || null,
    render: (text, record) => (
      <Render name={'date-cell'}>{text}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Expiration</TableHeaderTitle>,
    dataIndex: 'expiration_date',
    key: 'expiration_date',
    width: '15%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'expiration_date' && params.sorter.order) || null,
    render: (text, record) => (
      <Render name={'date-cell'}>{record.is_perpetual_license ? 'Perpetual' : text ? moment(text, 'YYYY-MM-DD').format('MM-DD-YYYY') : 'See Notes'}</Render>
    ),
  }
]

export const licenceColumnsRenewals = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: '10%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text, 'code')}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Type</TableHeaderTitle>,
    dataIndex: 'report_type',
    key: 'report_type',
    width: '20%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_type' && params.sorter.order) ||
      null,
    render: (text, record) => (
      record.type === 'renewal' ? <Render name={'jurisdiction-cell'}>{getDescription(params.store.license_renewals_types, record.license_type)}</Render>
        : <Render name={'jurisdiction-cell'}>{getDescription(params.store.reportTypes, record.report_type)}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>License/Report Title</TableHeaderTitle>,
    dataIndex: 'report_title',
    key: 'report_title',
    width: '55%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_title' && params.sorter.order) ||
      null,
    render: (text, record) => (
      record.type === 'renewal'
        ? <Render name={'jurisdiction-cell'}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {`${record.title} Renewal`}
          </div>
        </Render>
        : <Render name={'jurisdiction-cell'}>{record.report_title}</Render>

    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Due</TableHeaderTitle>,
    dataIndex: 'internal_due_date',
    key: 'internal_due_date',
    width: '15%',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'internal_due_date' && params.sorter.order) || null,
    render: (text, record) => (
      record.type === 'renewal'
        ? <Render name={'jurisdiction-cell'}>{record.renewal_date ? moment(record.renewal_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'See notes'}</Render>
        : <Render name={'jurisdiction-cell'}>{record.report_due ? moment(record.report_due, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'See notes'}</Render>
    ),

  },
  {
    title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
    dataIndex: 'status',
    key: 'status',
    align: 'left',
    width: '15%',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
    render: (text, record) => {
      return (
        record.type === 'renewal' ?
          <Observer>
            {() => (
              <Render
                name={'status-cell'}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '10px 1fr',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <Status bg={getLicenseStatusColor(text)}>
                  <div />
                </Status>
                {getSecondElement(text, params?.store?.renewals_all_statuses)}
              </Render>
            )}
          </Observer>
          : <Observer>
            {() => (
              <Render
                name={'status-cell'}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '10px 1fr',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <Status bg={getReportStatusColor(record.status, getRecord({ record, ...params }))}>
                  <div />
                </Status>
                {getSecondElement(record.status, params?.store?.report_source_statuses)}
              </Render>
            )}
          </Observer>
      )

    },
    ...(params.status || {}),
  },
  {
    title: (
      <TableHeaderTitle name={'title'}>
        <img src={flag} alt='flag-icon' />
      </TableHeaderTitle>
    ),
    dataIndex: 'is_starred',
    key: 'is_starred',
    align: 'left',
    width: '5%',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'is_starred' && params.sorter.order) || null,
    render: (text, record) => (
      <Render name={'is-starred-cell'} style={{ display: 'flex', justifyContent: 'center' }}>
        {record.is_priority && <img src={flag} alt='flag-icon' />}
      </Render>
    ),
    ...(params.is_starred || {}),
  }
]
const columnsCoreLicenceTasksReporting = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: 'jurisdiction',
    key: 'jurisdiction',
    width: 200,
    align: 'left',
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text)}</Render>
    ),
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Type</TableHeaderTitle>,
    dataIndex: 'report_type',
    key: 'report_type',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_type' &&
        params.sorter.order) ||
      null,
    width: 300,
    // render: (text, record) => <Render name={'report-type-cell'}>{text}</Render>,
    render: (text, record) =>
      record.type === 'renewal'
        ? <Render name={'report-type-cell'}>{getDescription(params.store.license_renewals_types, record?.license?.license_type)}</Render>
        : <Render name={'report-type-cell'}>{getDescription(params.store.report_source_report_types, record?.report_type)}</Render>,
    ...(params.report_type || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>License/Report Title</TableHeaderTitle>,
    dataIndex: 'report_title',
    key: 'report_title',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_title' && params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) =>
      record.type === 'renewal' ?
        <Render name={'report-title-cell'}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {`${record?.license?.license_title} Renewal`}
          </div>
        </Render>
        : <Render name={'report-title-cell'}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {record.report_title}
            {record.is_new_report &&
              <Tag
                style={{ width: 68, fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9, marginBottom: 5 }}
                color="#F24722">
                New Report
              </Tag>
            }
            {record.is_new_requirement &&
              <Tag
                style={{ width: 95, fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9 }}
                color="#DF7411">
                New Requirement(s)
              </Tag>}

          </div>
        </Render>,
    ...(params.report_title || {}), columnsCore
  },
  {
    title: <TableHeaderTitle name={'title'}>Due</TableHeaderTitle>,
    dataIndex: 'internal_due_date',
    key: 'internal_due_date',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'internal_due_date' && params.sorter.order) ||
      null,
    width: 115,
    render: (text, record) =>
      record.type === 'renewal' ?
        <div name={'report-due-cell'}>{record.renewal_date ? moment(record.renewal_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'See Notes'}</div>
        : <div name={'report-due-cell'}>{record.report_due ? moment(record.report_due, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'See Notes'}</div>,
  }
]
const columnsCoreLicenceTasksPending = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: 'jurisdiction',
    key: 'jurisdiction',
    width: 200,
    align: 'left',
    render: (text, record) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, record?.license?.jurisdiction)}</Render>
    ),
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Type</TableHeaderTitle>,
    dataIndex: 'license_type',
    key: 'license_type',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_type' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) => <Render name={'report-type-cell'}>{getDescription(params.store.license_renewals_types, record?.license?.license_type)}</Render>,
    ...(params.report_type || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>License Title</TableHeaderTitle>,
    dataIndex: 'license_title',
    key: 'license_title',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'license_title' && params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) => <Render name={'report-title-cell'}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {record?.license?.license_title}
      </div>
    </Render>,
    ...(params.report_title || {}), columnsCore
  },
  {
    title: <TableHeaderTitle name={'title'}>Due</TableHeaderTitle>,
    dataIndex: 'internal_due_date',
    key: 'internal_due_date',
    align: 'left',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'internal_due_date' && params.sorter.order) ||
      null,
    width: 115,
    render: (text) => <div name={'report-due-cell'}>{text ? moment(text, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'See Notes'}</div>,
  }
]


const columnsCore = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Reference #</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'reference_number', ...params }),
    key: 'reference_number',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text) => <Render name={'reference-number-cell'}>{text}</Render>,
    ...(params.reference_number || {}),
  },

  {
    title: <TableHeaderTitle name={'title'}>Topic</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'topics', ...params }),
    key: 'topics',
    sorter: true,
    width: 200,
    sortOrder:
      (params.sorter?.columnKey === 'topics' && params.sorter.order) || null,
    render: (_, record) => (
      <Render name={'topics-cell'}>
        <FirstLetterUppercase>
          {getString(
            params.store.topics,
            getRecord({ record, ...params })?.topics
          )}
        </FirstLetterUppercase>
      </Render>
    ),
    ...(params.topics || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Juris</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: 200,
    align: 'center',
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text)}</Render>
    ),
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
  ...(params.lob
    ? [
      {
        title: <TableHeaderTitle name={'title'}>Line of Business</TableHeaderTitle>,
        dataIndex: getDataIndex({ field: 'line_of_business', ...params }),
        key: 'line_of_business',
        width: 300,
        align: 'center',
        render: (text) => <div name={'line-of-business-cell'}>{text}</div>,
        sorter: true,
        sortOrder:
          (params.sorter?.columnKey === 'jurisdiction' &&
            params.sorter.order) ||
          null,
        ...(params.line_of_business || {}),
      },
    ]
    : []),
]
const liteColumnsCore = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Reference #</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'reference_number', ...params }),
    key: 'reference_number',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text) => <Render name={'reference_number_cell'}>{text}</Render>,
    ...(params.reference_number || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Juris</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: 200,
    align: 'center',
    render: (text) => (
      <Render name={'jurisdiction_cell'}>{getDescription(params.store.jurs, text)}</Render>
    ),
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Topic</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'topics', ...params }),
    key: 'topics',
    sorter: true,
    width: 200,
    sortOrder:
      (params.sorter?.columnKey === 'topics' && params.sorter.order) || null,
    render: (_, record) => (
      <Render name={'topics_cell'}>
        <FirstLetterUppercase>
          {getString(
            params.store.topics,
            getRecord({ record, ...params })?.topics
          )}
        </FirstLetterUppercase>
      </Render>
    ),
    ...(params.topics || {}),
  },
  ...(params.lob
    ? [
      {
        title: <TableHeaderTitle name={'title'}>Line of Business</TableHeaderTitle>,
        dataIndex: getDataIndex({ field: 'line_of_business', ...params }),
        key: 'line_of_business',
        width: 300,
        align: 'center',
        render: (text) => <div name={'lob_cell'}>{text}</div>,
        sorter: true,
        sortOrder:
          (params.sorter?.columnKey === 'jurisdiction' &&
            params.sorter.order) ||
          null,
        ...(params.line_of_business || {}),
      },
    ]
    : []),
]

const liteColumnsCoreNoJurs = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Reference #</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'reference_number', ...params }),
    key: 'reference_number',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text) => <Render name={'reference_number_cell'}>{text}</Render>,
    ...(params.reference_number || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Topic</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'topics', ...params }),
    key: 'topics',
    sorter: true,
    width: 200,
    sortOrder:
      (params.sorter?.columnKey === 'topics' && params.sorter.order) || null,
    render: (_, record) => (
      <Render name={'topics_cell'}>
        <FirstLetterUppercase>
          {getString(
            params.store.topics,
            getRecord({ record, ...params })?.topics
          )}
        </FirstLetterUppercase>
      </Render>
    ),
    ...(params.topics || {}),
  },
  ...(params.lob
    ? [
      {
        title: <TableHeaderTitle name={'title'}>Line of Business</TableHeaderTitle>,
        dataIndex: getDataIndex({ field: 'line_of_business', ...params }),
        key: 'line_of_business',
        width: 300,
        align: 'center',
        render: (text) => <div name={'lob_cell'}>{text}</div>,
        sorter: true,
        sortOrder:
          (params.sorter?.columnKey === 'jurisdiction' &&
            params.sorter.order) ||
          null,
        ...(params.line_of_business || {}),
      },
    ]
    : []),
]

const ultraLiteColumnsCore = (params) => [
  {
    title: <TableHeaderTitle style={{ marginLeft: 10 }}>Reference #</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'reference_number', ...params }),
    key: 'reference_number',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) => <Render
      name={'reference_number_cell'}
      style={{ marginLeft: 10 }}>
      <Link
        to={record.item_url}
      >
        {text}
      </Link>
    </Render>,
    ...(params.reference_number || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Juris</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: 200,
    align: 'center',
    render: (text) => (
      <Render name={'jurisdiction_cell'}>{text}</Render>
    ),
    sorter: false,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
]



const columnsCoreReports = (params) => [
  {
    title: <TableHeaderTitle name={'title'}>Report Title</TableHeaderTitle>,
    dataIndex: 'report_title',
    key: 'report_title',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_title' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) => <Render name={'report-title-cell'}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {text}

        {record.is_new_report &&
          <Tag
            style={{ width: 68, fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9, marginBottom: 5 }}
            color="#F24722">
            New Report
          </Tag>
        }
        {record.is_new_requirement &&
          <Tag
            style={{ width: 95, fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9 }}
            color="#DF7411">
            New Requirement(s)
          </Tag>}

      </div>
    </Render>,
    ...(params.report_title || {}), columnsCore
  },
  {
    title: <TableHeaderTitle name={'title'}>Report Type</TableHeaderTitle>,
    dataIndex: 'report_type',
    key: 'report_type',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_type' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text) => <Render name={'report-type-cell'}>{getDescription(params.store.reportTypes, text)}</Render>,
    ...(params.report_type || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: 'jurisdiction',
    key: 'jurisdiction',
    width: 200,
    align: 'center',
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text)}</Render>
    ),
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'jurisdiction' && params.sorter.order) ||
      null,
    ...(params.jurisdiction || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>{toJS(params.store.searchableObject?.task_assignee) && toJS(params.store.searchableObject?.task_assignee).length > 0 ? 'Task Due' : 'Report Due'}</TableHeaderTitle>,
    dataIndex: toJS(params.store.searchableObject?.task_assignee)
      && toJS(params.store.searchableObject?.task_assignee).length > 0 ? 'task_due_date' : 'report_due',
    key: toJS(params.store.searchableObject?.task_assignee)
      && toJS(params.store.searchableObject?.task_assignee).length > 0 ? 'task_due_date' : 'report_due',
    align: 'left',
    sorter: true,
    width: 200,
    sortOrder:
      toJS(params.store.searchableObject?.task_assignee)
        && toJS(params.store.searchableObject?.task_assignee).length > 0
        ? (params.sorter?.columnKey === 'task_due_date' && params.sorter.order) ||
        null :
        (params.sorter?.columnKey === 'report_due' && params.sorter.order) ||
        null,
    render: (text, record) =>
      <Render
        name={toJS(params.store.searchableObject?.task_assignee) && toJS(params.store.searchableObject?.task_assignee).length > 0 ? 'task-due-date-cell' : 'report-due-cell'}
      >{
          toJS(params.store.searchableObject?.task_assignee)
            && toJS(params.store.searchableObject?.task_assignee).length > 0 ? getDate(text)
            : record.report_due_date_is_variable ? 'Variable' : record.report_due_date_is_upon_request ? <div>Upon Request: <br />See Notes</div> :
              getDate(text)
        }
      </Render >,
  }
]

const columnsCoreReporting = (params) => [
  {
    title: <TableHeaderTitle style={{ marginLeft: 10 }}>Report Title</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'report_title', ...params }),
    key: 'report_title',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'report_title' &&
        params.sorter.order) ||
      null,
    width: 300,
    render: (text, record) =>
      <Render name={'report-title-cell'}
        style={{ marginLeft: 10 }}
      >
        {text}{' '}
        <Link
          to={record.item_url}
          style={{
            textDecoration: 'underline',
            color: 'var(--text)',
            marginLeft: 5,
          }}
          target='_blank'
        >
          View Details
        </Link>
      </Render>,
    ...(params.report_title || {}), columnsCore
  },
  {
    title: <TableHeaderTitle name={'title'}>Juris.</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'jurisdiction', ...params }),
    key: 'jurisdiction',
    width: 115,
    // align: 'center',
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{getDescription(params.store.jurs, text)}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Report Item Owner</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'report_owner', ...params }),
    key: 'report_owner',
    width: 100,
    sorter: false,
    render: (text) => <Render name={'report-owner-cell'}>{text}</Render>,
  },
  {
    title: <TableHeaderTitle name={'title'}>Report Type</TableHeaderTitle>,
    dataIndex: getDataIndex({ field: 'report_type', ...params }),
    key: 'report_type',
    width: 115,
    sorter: false,
    render: (text) => <Render name={'report-type-cell'}>{getDescription(params.store.reportTypes, text)}</Render>,
  },
  // {
  //   title: <TableHeaderTitle name={'title'}>Line of Business</TableHeaderTitle>,
  //   dataIndex: 'line_of_business',
  //   key: 'line_of_business',
  //   width: 350,
  //   align: 'start',
  //   render: (arr) => arr.map(item => getDescription(params.store.lineOfBusinesses, item)).join(', '),
  //   sorter: false,
  // },
  {
    title: <TableHeaderTitle name={'title'}>Report Due</TableHeaderTitle>,
    dataIndex: 'report_due',
    key: 'report_due',
    align: 'left',
    sorter: false,
    width: 115,
    render: (text) => <div name={'report-due-cell'}>{text}</div>,
  }
]

export const columnsCoreTasksReporting = (params) => [
  {
    title: <TableHeaderTitle >{params.isReportSource ? 'Report Title' : 'Reference Number'}</TableHeaderTitle>,
    dataIndex: params.isReportSource ? 'report_title' : 'reference_number',
    key: params.isReportSource ? 'report_title' : 'reference_number',
    sorter: true,
    sortOrder: params.isReportSource ?
      (params.sorter?.columnKey === 'report_title' &&
        params.sorter.order) ||
      null :
      (params.sorter?.columnKey === 'reference_number' &&
        params.sorter.order) ||
      null,
    width: '15%',
    render: (text, record) =>
      <Render name={'title-cell'}

      >
        {text}{' '}
        <Link
          to={record.item_url}
          style={{
            textDecoration: 'underline',
            color: 'var(--text)',
            marginLeft: 5,
          }}
          target='_blank'
        >
          View Details
        </Link>
      </Render>,
    ...(params.report_title || {}), columnsCore
  },
  {
    title: <TableHeaderTitle name={'title'}>Jurisdictions</TableHeaderTitle>,
    dataIndex: 'jurisdiction',
    key: 'jurisdiction',
    width: '10%',
    // align: 'center',
    render: (text) => (
      <Render name={'jurisdiction-cell'}>{text}</Render>
    ),
  },
  {
    title: <TableHeaderTitle name={'title'}>Task Owner</TableHeaderTitle>,
    dataIndex: 'assignee',
    key: 'assignee',
    width: '18%',
    sorter: false,
    render: (text) => <Render name={'task-owner-cell'}>{text}</Render>,
  },
  {
    title: <TableHeaderTitle name={'title'}>{params.isReportSource ? 'Report Item Owner' : 'Compliance Item Owner'}</TableHeaderTitle>,
    dataIndex: params.isReportSource ? 'report_owner' : 'compliance_owner',
    key: params.isReportSource ? 'report_owner' : 'compliance_owner',
    width: '18%',
    sorter: false,
    render: (text) => <Render name={params.isReportSource ? 'report_owner_cell' : 'compliance_owner_cell'}>{text}</Render>,
  },
  {
    title: <TableHeaderTitle name={'title'}>Task Description</TableHeaderTitle>,
    dataIndex: 'description',
    key: 'description',
    align: 'left',
    sorter: false,
    width: '40%',
    render: (text) => <div name={'report-due-cell'}>{text}</div>,
  },
  {
    title: <TableHeaderTitle name={'title'}>Task Due Date</TableHeaderTitle>,
    dataIndex: 'due_date',
    key: 'due_date',
    align: 'left',
    sorter: true,
    width: '10%',
    sortOrder:
      (params.sorter?.columnKey === 'due_date' && params.sorter.order) ||
      null,
    render: (text) => <Render name={'task-due-date-cell'}>{text}</Render>,
    ...(params.task_due_date || {}),
  },
  {
    title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
    render: (text, record) => {
      return (
        <Observer>
          {() => (
            <Render
              name={'status-cell'}
              style={{
                display: 'grid',
                gridTemplateColumns: '10px 1fr',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Status bg={getTaskReportStatusColor(text, getRecord({ record, ...params }))}>
                <div />
              </Status>
              {statusesTaskReport?.[text]?.[0]}
            </Render>
          )}
        </Observer>
      )
    },
    ...(params.status || {}),
  },
  {
    title: (
      <TableHeaderTitle name={'title'}>
        <img src={flag} alt='flag-icon' />
      </TableHeaderTitle>
    ),
    dataIndex: 'is_starred',
    key: 'is_starred',
    align: 'center',
    width: '5%',
    sorter: true,
    sortOrder:
      (params.sorter?.columnKey === 'is_starred' && params.sorter.order) || null,
    render: (text) => (
      <Render name={'is-starred-cell'} style={{ display: 'flex', justifyContent: 'center' }}>
        {text && <img src={flag} alt='flag-icon' />}
      </Render>
    ),
    ...(params.is_starred || {}),
  }
]

const columnWithStatus = (params) => ({
  title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
  dataIndex: 'status',
  key: 'status',
  width: '100px',
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      <Observer>
        {() => (
          <Render
            name={'status-cell'}
            style={{
              display: 'grid',
              gridTemplateColumns: '10px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
          >
            {!params.isMyTasks && <Status bg={params.isReportSource
              ? getReportStatusColor(text, getRecord({ record, ...params }))
              : getStatusColor(text, getRecord({ record, ...params }))}>
              <div />
            </Status>}
            {params.isMyTasks && <Status bg={getTrackingMyTaskStatusColor(record?.my_tasks_status, record)}>
              <div />
            </Status>}
            {!params.isMyTasks && statuses?.[text]?.[0]}
            {params.isMyTasks && trackingMyTaskStatuses?.[record.my_tasks_status]?.[0]}
          </Render>
        )}
      </Observer>
    )
  },
  ...(params.status || {}),
})
const columnWithStatusLicense = (params) => ({
  title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
  dataIndex: 'status',
  key: 'status',
  width: '100px',
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      record.type === 'renewal' ?
        <Observer>
          {() => (
            <Render
              name={'status-cell'}
              style={{
                display: 'grid',
                gridTemplateColumns: '10px 1fr',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <Status bg={renewalsLicensesMyTaskStatuses?.[record.my_tasks_status]?.[1]}>
                <div />
              </Status>
              {renewalsLicensesMyTaskStatuses?.[record.my_tasks_status]?.[0]}
            </Render>
          )}
        </Observer>
        : record.type === 'report' ?
          <Observer>
            {() => (
              <Render
                name={'status-cell'}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '10px 1fr',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <Status bg={reportMyTaskStatuses?.[record.my_tasks_status]?.[1]}>
                  <div />
                </Status>
                {reportMyTaskStatuses?.[record.my_tasks_status]?.[0]}
              </Render>
            )}
          </Observer>
          : <Observer>
            {() => (
              <Render
                name={'status-cell'}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '10px 1fr',
                  alignItems: 'center',
                  gap: 10,
                }}
              >
                <Status bg={renewalsLicensesMyTaskStatuses?.[record.my_tasks_status]?.[1]}>
                  <div />
                </Status>
                {renewalsLicensesMyTaskStatuses?.[record.my_tasks_status]?.[0]}
              </Render>
            )}
          </Observer>
    )

  },
  ...(params.status || {}),
})
const columnWithStatusLite = (params) => ({
  title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
  dataIndex: 'status',
  key: 'status',
  width: '100px',
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      <Observer>
        {() => (
          <Render
            name={'status-cell'}
            style={{
              display: 'grid',
              gridTemplateColumns: '10px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Status bg={text === 'closed' || text === 'Closed' ? 'var(--grey)' : 'var(--blue)'}>
              <div />
            </Status>
            {text === 'closed' || text === 'Closed' ? 'Archived' : 'Tracking'}
          </Render>
        )}
      </Observer>
    )
  },
  ...(params.status || {}),
})
const columnWithStatusUltraLite = (params) => ({
  title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
  dataIndex: 'status',
  key: 'status',
  width: '100px',
  sorter: false,
  sortOrder:
    (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      <Observer>
        {() => (
          <Render
            name={'status-cell'}
            style={{
              display: 'grid',
              gridTemplateColumns: '10px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Status bg={text === 'Closed' ? 'var(--grey)' : 'var(--blue)'}>
              <div />
            </Status>
            {text === 'Closed' ? 'Archived' : 'Tracking'}

          </Render>
        )}
      </Observer>
    )
  },
  ...(params.status || {}),
})
const columnWithReportStatus = (params) => ({
  title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
  dataIndex: 'status',
  key: 'status',
  width: '130px',
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      <Observer>
        {() => (
          <Render
            name={'status-cell'}
            style={{
              display: 'grid',
              gridTemplateColumns: '5px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
          >
            {!params.isMyTasks &&
              <Status bg={getReportStatusColor(text, getRecord({ record, ...params }))}>
                <div />
              </Status>}
            {params.isMyTasks && <Status bg={getReportMyTaskStatusColor(text, record)}>
              <div />
            </Status>}
            < div style={{ display: 'flex', flexDirection: 'row', width: 100 }}>
              {!params.isMyTasks && statuses?.[text]?.[0]}
              {!params.isMyTasks && text === 'in_progress_deficiency' && <img style={{ marginLeft: 5 }} src={image} alt='warning' />} <br />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '90px', marginTop: '-10px', justifyContent: 'center' }}>
              {!params.isMyTasks && text === 'in_progress_deficiency' && record.in_progress_deficiency_date && `${params.isReporting ? 'Due:' : ''} ${moment(record.in_progress_deficiency_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}`}
              {!params.isMyTasks && text === 'closed_submitted' && record.closed_submitted_date && params.isReporting && `${params.isReporting ? 'on ' : ''}${moment(record.closed_submitted_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}`}
            </div>
            {params.isMyTasks && reportMyTaskStatuses?.[record.my_tasks_status]?.[0]}
          </Render>
        )}
      </Observer>
    )
  },
  ...(params.status || {}),
})

const columnWithReportTasksStatus = (params) => ({
  title: <TableHeaderTitle name={'title'}>Status</TableHeaderTitle>,
  dataIndex: 'my_tasks_status',
  key: 'my_tasks_status',
  width: '100px',
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'my_tasks_status' && params.sorter.order) || null,
  render: (text, record) => {
    return (
      <Observer>
        {() => (
          <Render
            name={'my-tasks-status-cell'}
            style={{
              display: 'grid',
              gridTemplateColumns: '10px 1fr',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Status bg={getReportMyTaskStatusColor(text, record)}>
              <div />
            </Status>
            {reportMyTaskStatuses?.[text]?.[0]}
          </Render>
        )}
      </Observer>
    )
  },
  ...(params.status || {}),
})

const columnsWithDue = ({ sorter, isMyTasks, activeTab }) => ({
  title: (
    <TableHeaderTitle name={'title'}>{isMyTasks ? 'Due Date' : 'Asgmt Due'} </TableHeaderTitle>
  ),
  dataIndex: isMyTasks ? 'closest_task_due_date' : 'primary_due_date',
  key: isMyTasks ? 'closest_task_due_date' : 'primary_due_date',
  align: 'left',
  sorter: true,
  sortOrder:
    (sorter?.columnKey === (isMyTasks ? 'closest_task_due_date' : 'primary_due_date') &&
      sorter.order) ||
    null,
  render: (text, record) => (
    <Render
      name={isMyTasks ? 'closest-task-due-date-cell' : 'primary-due-date-cell'}
      style={{
        display: 'grid',
        gridTemplateColumns: isMyTasks ? '1fr' : '10px 1fr',
        alignItems: 'center',
        gap: 10,
      }}
    >
      {!isMyTasks && (
        <Status
          bg={getStatusColor(
            getRecord({ record, isMyTasks, activeTab })?.status,
            getRecord({ record, isMyTasks, activeTab })
          )}
        >
          <div />
        </Status>
      )}

      {isMyTasks ?
        getDate(
          record?.closest_task_due_date)
        : getDate(text)}
    </Render>
  ),
})

const columnFlag = (params) => ({
  title: (
    <TableHeaderTitle name={'title'}>
      <img src={flag} alt='flag-icon' />
    </TableHeaderTitle>
  ),
  dataIndex: 'is_starred',
  key: 'is_starred',
  align: 'center',
  width: 50,
  sorter: true,
  sortOrder:
    (params.sorter?.columnKey === 'is_starred' && params.sorter.order) || null,
  render: (text) => (
    <Render name={'is-starred-cell'} style={{ display: 'flex', justifyContent: 'center' }}>
      {text && <img src={flag} alt='flag-icon' />}
    </Render>
  ),
  ...(params.is_starred || {}),
})

const columnComplianceOwner = (params) => ({
  title: <TableHeaderTitle name={'title'}>Compliance Item Owner</TableHeaderTitle>,
  dataIndex: getDataIndex({ field: 'compliance_owner', ...params }),
  key: 'compliance_owner',
  width: 170,
  sorter: false,
  render: (text) => <Render name={'compliance-owner-cell'}>{text}</Render>,
  ...(params.compliance_owner || {}),
})

export const columnsCommon = (params) => [
  ...toJS(params.store.company[0].compliance_source_mode) === 'compliance_source_lite'
    ? liteColumnsCoreNoJurs(params)
    : columnsCore(params),
  params.isComplianceItemReport ? columnComplianceOwner(params) : {},
  columnsAdoptedDate(params),
  columnsComplianceDate(params),
  toJS(params.store.searchableObject?.primary_due_date_before) && !params.isComplianceItemReport
    ? columnsWithDue(params)
    : toJS(params.store.company[0].compliance_source_mode) === 'compliance_source_lite'
      ? columnWithStatusLite(params) : columnWithStatus(params),
  columnFlag(params),
]

export const columnsCommonLite = (params) => [
  ...toJS(params.store.company[0].compliance_source_mode) === 'compliance_source_lite'
    ? liteColumnsCore(params)
    : columnsCore(params),
  columnsComplianceDate(params),
  toJS(params.store.searchableObject?.primary_due_date_before)
    ? columnsWithDue(params)
    : columnWithStatusLite(params),
  columnFlag(params),
]


export const ultraLiteCommon = (params) => [
  ...ultraLiteColumnsCore(params),
  columnsAdoptedDateUltraLite(params),
  columnWithStatusUltraLite(params),
]

export const columnsCommonReports = (params) => [
  ...columnsCoreReports(params),
  toJS(params.store.company[0].report_source_mode) === 'report_source_lite' ?
    columnWithStatusLite(params) : columnWithReportStatus(params),
  columnFlag(params),
]

export const columnsTasksReports = (params) => [
  ...columnsCoreReports(params),
  columnWithReportTasksStatus(params),
  columnFlag(params),
]

export const columnsTasks = (params) => [
  ...columnsCore(params),
  columnsWithDue(params),
  columnWithStatus(params),
  columnFlag(params),
]

export const columnsLicenceTasksReporting = (params) => [
  ...columnsCoreLicenceTasksReporting(params),
  columnWithStatusLicense(params),
]
export const columnsLicenceTasksPending = (params) => [
  ...columnsCoreLicenceTasksPending(params),
  columnWithStatusLicense(params),
]

export const columnsReportsReporting = (params) => [
  ...columnsCoreReporting(params),
  toJS(params.store.company[0].report_source_mode) === 'report_source_lite' ?
    columnWithStatusLite(params) : columnWithReportStatus(params),
  columnFlag(params),
]

export const columnsUltraLite = (params) => [
  ...columnsWithRefNumber(params),
]