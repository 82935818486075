export const tabs = [
  {
    key: 'main',
    title: 'Tracking',
    disabledFor: ['member'],
  },
  { key: 'monitor', title: 'Monitor', disabledFor: ['member'] },
  { key: 'closed', title: 'Closed', disabledFor: ['member'] },
  { key: 'all', title: 'All', disabledFor: ['member'] },
  { key: 'myTasks', title: 'My Tasks' },
  { key: 'closed', title: 'Closed', disabledFor: ['owner', 'admin', 'compliance_owner'] },
  { key: 'all', title: 'All', disabledFor: ['owner', 'admin', 'compliance_owner'] },
]

export const liteTabs = [
  {
    key: 'main',
    title: 'Tracking',
    disabledFor: ['member'],
  },
  { key: 'closed', title: 'Archive' },
]
export const reportTabs = [
  {
    key: 'main',
    title: 'General',
    disabledFor: ['member'],
  },
  { key: 'apcd', title: 'APCD', disabledFor: ['member'] },
  { key: 'closed', title: 'Closed', disabledFor: ['member'] },
  { key: 'all', title: 'All', disabledFor: ['member'] },
  { key: 'myTasks', title: 'My Tasks' },
  { key: 'closed', title: 'Closed', disabledFor: ['owner', 'admin', 'report_owner'] },
  { key: 'all', title: 'All', disabledFor: ['owner', 'admin', 'report_owner'] },
  { key: 'calendar', title: 'Calendar', disabledFor: ['member'] },
]

export const reportLiteTabs = [
  {
    key: 'main',
    title: 'General',
    disabledFor: ['member'],
  },
  { key: 'apcd', title: 'APCD', disabledFor: ['member'] },
  { key: 'all', title: 'All', disabledFor: ['member'] },
  { key: 'closed', title: 'Archive' },
  { key: 'calendar', title: 'Calendar', disabledFor: ['member'] },
]

export const lsTabs = [
  { key: 'main', title: 'Pending' },
  { key: 'held', title: 'Held' },
  { key: 'na', title: 'N/A', divider: true },
  { key: 'renewals_reporting', title: 'Renewals & Reporting' },
  { key: 'closed', title: 'Closed' },
  { key: 'my_tasks', title: 'My Tasks' },
  { key: 'calendar', title: 'Calendar', },
  { key: 'all', title: 'All', divider: true },
  { key: 'apcd', title: 'APCD' }
]

export const lsPendingTabs = [
  // { key: 'all', title: 'All' },
  // { key: 'not_assessed', title: 'Not Assessed' },
  // { key: 'monitor', title: 'Monitor' },
  // { key: 'na', title: 'N/A' },
]
export const lsInitialAppTabs = [
  // { key: 'ia_all', title: 'All' },
  // { key: 'ia_unassigned', title: 'Unassigned' },
  // { key: 'ia_in_progress', title: 'In Progress' },
  // { key: 'ia_in_progress_ready_for_final_review', title: 'Ready For Final Review' },
  // { key: 'ia_in_progress_submitted_to_govt', title: 'Submitted' },
  // { key: 'ia_in_progress_deficiency', title: 'Deficiency' },
]

export const lsHeldTabs = [
  // { key: 'held', title: 'Held' },
]

export const lsRenewalsTabs = [
  { key: 'renewals_reporting', title: 'Renewals & Reporting' },
  { key: 'apcd', title: 'APCD' },
  { key: 'closed', title: 'Closed' },
  { key: 'all_renewals', title: 'All' }
]

export const lsTasksTabs = [
  { key: 'pending_tasks', title: 'Pending Assessments' },
  { key: 'assigned', title: 'Renewals & Reporting' },
]

export const lsCalendarTabs = [
  { key: 'calendar', title: 'Calendar' },
]