import {
  ContentState,
  convertFromHTML,
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import 'draft-js/dist/Draft.css'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../api'
import boldIcon from '../../../../assets/images/bold.svg'
import italicIcon from '../../../../assets/images/italic.svg'
import underlineIcon from '../../../../assets/images/underline.svg'
import Button from '../../../../components/common/Button/button'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { DocumentsPlaceholder } from '../right.style'
import './DraftStyleDefault.css'
import Item from './Item'
import EditorSaveModal from './EditorSaveModal'
import { Toolbar, ToolButton, Wrap, WrapEditor } from './style'

const styleMap = {
  BOLD: {
    fontFamily: 'var(--bold)',
  },
}

const RichTextExample = ({ legislative, isReportSource = false, isLicenseSource = false, reportId, detailsState, handleDetailsState, type }) => {
  const store = useStore()
  const params = useParams()
  const editorBlockRef = useRef(null)
  const editorRef = useRef(null)
  const [data, setData] = useState(null)
  const [active, setActive] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })
  const [visibleField, setVisibleField] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [impactedClients, setImpactedClients] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const isEmpty = !editorState.getCurrentContent().hasText()

  const ID = params.id || store.currentLegislative.id

  const checkIsOnlySpaces = () => {
    const content = editorState.getCurrentContent();
    const hasText = content.hasText();
    const plainText = content.getPlainText().trim();
    return hasText && plainText.length === 0;
  };

  const valueName = 'details_html'

  const handleModalClose = () => {
    setShowModal(false)
    setHasUnsavedChanges(false)
    if (editorRef.current) {
      editorRef.current.focus()
    }
  }

  const onSubmit = async (onLoad) => {
    if (isEmpty || checkIsOnlySpaces()) return null

    setLoading(true)

    const value = stateToHTML(editorState.getCurrentContent())
    const url = isReportSource
      ? `/report_company/${ID}/details/`
      : `/legislative-details/${ID}/`
    try {
      const data = await api(
        url,
        {
          details_html: value,
        },
        'PUT'
      )
      if (!data.errors) {
        setData(data)
        onLoad && onLoad()
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const getTagsList = () => {
    api(`/legislative/${legislative}/impacted_clients/`, {}, 'GET').then((data) => {
      setImpactedClients(data.impacted_clients)
    })
  }


  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const transformText = (e, type) => {
    setActive({ ...active, [type]: !active[type] })

    // onMouseDown and e.preventDefault because editor losses focus if you use onClick
    e.preventDefault()

    let nextState = RichUtils.toggleInlineStyle(editorState, type)

    setEditorState(nextState)
  }
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!showModal
        && editorBlockRef.current
        && !editorBlockRef.current.contains(e.target)
        && hasUnsavedChanges
        && !(isEmpty || checkIsOnlySpaces())
      ) {
        e.preventDefault()
        e.stopPropagation()
        setShowModal(true)
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
    if (hasUnsavedChanges) {
      document.addEventListener('click', handleClickOutside, true)
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [showModal, hasUnsavedChanges, isEmpty])

  useEffect(() => {
    const url = isReportSource
      ? `/report_company/${ID}/`
      : `/legislative-details/${ID}/`;

    api(url, {}, 'GET').then((data) => {
      setData(data);
    });
    setEditorState(EditorState.createEmpty())
  }, [isReportSource, ID, store?.currentLegislative?.report?.id]);

  useEffect(() => {
    if (!isReportSource && !isLicenseSource) {
      getTagsList()
    }
  }, [isReportSource, legislative])

  useEffect(() => {
    if (data?.details_html) {
      const blocksFromHTML = convertFromHTML(data.details_html)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(state))
      setVisibleField(false)
    } else {
      // setEditorState(EditorState.createEmpty())
      setVisibleField(true)
    }
  }, [data])

  useEffect(() => {
    if (isEmpty)
      setActive({
        BOLD: false,
        ITALIC: false,
        UNDERLINE: false,
      })
  }, [isEmpty])

  useEffect(() => {
    return () => {
      const rawState = convertToRaw(editorState.getCurrentContent());
      handleDetailsState(rawState);
    }
  }, [editorState]);

  useEffect(() => {
    if (detailsState) {
      const loadedState = convertFromRaw(detailsState);
      const newEditorState = EditorState.createWithContent(loadedState);
      setEditorState(newEditorState);
    }
  }, []);



  const getEditor = (isReportSource) => {
    const isTrackingOwner = checkRole(
      [store.user?.[0]?.member_type],
      ['admin', 'owner', 'compliance_owner']
    );
    const isReportOwner = checkRole(
      [store.user?.[0]?.report_source_role],
      ['report_owner', 'owner', 'admin']
    );
    const canEdit =
      isReportSource && isReportOwner && visibleField
        ? true
        : !isReportSource && isTrackingOwner && visibleField
          ? true
          : false;

    const toolbarButtons = (
      <Toolbar name={'toolbar'}>
        <ToolButton
          name={'bold'}
          onMouseDown={(e) => transformText(e, 'BOLD')}
          active={active['BOLD']}
        >
          <img src={boldIcon} alt='bold-icon' />
        </ToolButton>
        <ToolButton
          name={'italic'}
          onMouseDown={(e) => transformText(e, 'ITALIC')}
          active={active['ITALIC']}
        >
          <img src={italicIcon} alt='italic-icon' />
        </ToolButton>
        <ToolButton
          name={'underline'}
          onMouseDown={(e) => transformText(e, 'UNDERLINE')}
          active={active['UNDERLINE']}
        >
          <img src={underlineIcon} alt='underline-icon' />
        </ToolButton>
      </Toolbar>
    );

    const editor = (
      <WrapEditor ref={editorRef}>
        {toolbarButtons}
        <Editor
          editorState={editorState}
          onChange={(editorState) => {
            setEditorState(editorState)
            setHasUnsavedChanges(true)
          }}
          handleKeyCommand={handleKeyCommand}
          placeholder={
            `Type in relevant comments for completing the ${isReportSource ? 'report' : 'compliance'} assessment.`
          }
          customStyleMap={{
            ...editorState.getCurrentInlineStyle(),
            ...styleMap,
          }}
        />
      </WrapEditor>
    );

    const buttons = (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, justifyContent: 'center' }}>
        <Button
          text={'Save'}
          style={{ fontSize: 12, marginBottom: 10 }}
          onClick={() => onSubmit(handleModalClose)}
          disabled={isEmpty || checkIsOnlySpaces()}
          loading={loading}
        />
        {data?.details_html && (
          <ButtonOrange
            style={{ marginBottom: 30 }}
            text={'Cancel'}
            onClick={() => setVisibleField(false)}
          />
        )}
      </div>
    );

    const view = (
      <div ref={editorBlockRef}>
        {showModal && (
          <EditorSaveModal
            onSubmit={() => { onSubmit(handleModalClose) }}
            onClose={handleModalClose}
          />
        )}
        {canEdit && (
          <Wrap>
            {editor}
            {buttons}
          </Wrap>
        )}
        {impactedClients.length > 0 &&
          <div style={{ marginBottom: 15, marginTop: 15 }}>
            <p style={{ fontSize: 14, fontFamily: 'var(--bold)', color: 'var(--text)' }}>
              {`Impacted Clients`}
            </p>
          </div>}
        {impactedClients.length > 0 && (
          impactedClients.map((item, index) => {
            return (
              <div key={index} style={{ fontFamily: 'var(--regular)', }}>
                {item}
              </div>
            )
          })
        )}
        {data?.details_html && <Item data={data} isReportSource={isReportSource} isLicenseSource={isLicenseSource} type={type} valueName={valueName} />}
        {!visibleField && isTrackingOwner && (
          <Button
            text={'Edit'}
            onClick={() => setVisibleField(true)}
            style={{ marginTop: 15, marginBottom: 30 }}
          />
        )}
        {!data?.details_html && (
          <DocumentsPlaceholder
            style={{
              marginTop: 15,
              borderBottom: '1px solid #bbb',
              paddingBottom: 15,
              marginBottom: '6em',
            }}
          >
            There are no comments associated with this item yet.
          </DocumentsPlaceholder>
        )}
      </div>
    );

    return view;
  };

  return <>{getEditor(isReportSource)}</>;

}

export default RichTextExample
