import React from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

const PopoverContent = styled.div`
  background-color: white;
  color: var(--text);
  font-family: var(--regular);
  font-size: 12px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: var(--grey);
  margin: 5px 0;
`;

const DescriptionHistoryPopover = ({ descriptionHistory }) => {
  const content = (
    <PopoverContent>
      {descriptionHistory.map((item, index) => (
        <div key={index}>
          <strong>{item.user.full_name}</strong> @{moment(item.created).format('MM/DD/YYYY hh:mmA')}
          <div>{item.old_description}</div>
          {index < descriptionHistory.length - 1 && <Divider />}
        </div>
      ))}
    </PopoverContent>
  );

  return (
    <Popover
      content={content}
      overlayStyle={{ zIndex: 1000000 }}
      overlayInnerStyle={{
        backgroundColor: 'white',
        color: 'black',
        border: '1px solid var(--grey)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      }}
      placement="right"
      arrow={false}
    >
      <strong style={{ fontFamily: 'var(--semiBold)', color: 'var(--blue)' }}>{' (edited)'}</strong>
    </Popover>
  );
};

export default DescriptionHistoryPopover;