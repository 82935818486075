import React, { useState } from 'react'
import { DocumentRight, DocumentWrap, DocumentRenameContainer, RenameInput, DocumentLink } from './right.style'
import Validation from '../../../components/common/Validation/validation'
import Button from '../../../components/common/Button/button'
import { useStore } from '../../../mobx-store/context'
import { observer } from 'mobx-react-lite'
import { api, downloadDoc } from '../../../api'
import { Dropdown, Menu } from 'antd'

const Document = observer(({ item,
  index,
  onDeleted,
  legislative,
  isReportSource,
  isLicenseSource,
  isRenewalsReporting,
  reportId,
  groupStatus,
  showDelete = true,
  updateFileName,
  isNeedHistory = false }) => {
  const store = useStore()
  const [show, setShow] = useState(false)
  const [renaming, setRenaming] = useState(false);
  const [newFileName, setNewFileName] = useState('');
  const file = item?.file_name?.split('/')[item?.file_name.split('/').length - 1]
  const docId = item?.id

  const handleRenameClick = () => {
    setRenaming(true);
    const nameWithoutExtension = file.substring(0, file.lastIndexOf('.'));
    setNewFileName(nameWithoutExtension);
  };
  const handleSaveClick = () => {
    setRenaming(false);
    const extension = file.substring(file.lastIndexOf('.'));
    const updatedFileName = newFileName;
    const url = `/file/${docId}/rename/`;
    const model = isReportSource
      ? 'ReportCompanyFile'
      : isLicenseSource
        ? (isRenewalsReporting ? 'RenewalLicenseFile' : 'LicenseCompanyFile')
        : 'LegislativeFile';
    const obj = {
      model,
      new_name: updatedFileName,
    };

    api(url, obj, 'PATCH').then((data) => {
      store.loadDocuments(legislative, '1', groupStatus, isReportSource, isLicenseSource, isRenewalsReporting);
      updateFileName(index, updatedFileName);
      setRenaming(false);
    }).catch((error) => {
      console.error('Error renaming file:', error);
    });
  };

  const handleFileNameChange = (e) => {
    setNewFileName(e.target.value);
  };

  const changeDocumentStatus = (id, status) => {
    const url = isLicenseSource && !isRenewalsReporting ?
      `/license_company/${store?.currentLegislative?.id}/files/${id}/change_status/`
      : isRenewalsReporting && localStorage.getItem('reportInLs') !== 'true' ?
        `/renewals_reporting/${store?.currentLegislative?.id}/files/${id}/change_status/`
        : `/report_company/${store?.currentLegislative?.id}/files/${id}/change_status/`
    const obj = { status: status }
    api(url, obj, isLicenseSource && !isRenewalsReporting ? 'PATCH' : 'PUT').then((data) => {
      store.loadDocuments(legislative, '1', groupStatus, isReportSource, isLicenseSource, isRenewalsReporting)
    })
  }

  const docStatusList = [
    {
      key: 1,
      value: 'working',
      label: 'Working',
    },
    {
      key: 2,
      value: 'finalized',
      label: 'Finalized',
    },
    {
      key: 3,
      value: 'submitted',
      label: 'Submitted',
    },
    {
      key: 3,
      value: 'deficiency',
      label: 'Deficiency',
    },
    {
      key: 4,
      value: 'other',
      label: 'Other',
    },
  ]

  const filteredDocStatusList = docStatusList.filter((item) => item.value !== groupStatus)
  const licenseId = store?.currentLegislative?.license_company_id || store?.currentLegislative?.id


  const documentStatusMenu = (
    <>
      <Menu>
        {
          filteredDocStatusList.map((item) => (
            <Menu.Item key={item.key} onClick={() =>
              changeDocumentStatus(docId, item.value)
            }>
              {item.label}
            </Menu.Item>
          ))
        }
      </Menu>
    </>
  )

  return (
    <>
      <DocumentWrap grey={index % 2 === 0}>
        <div>
          <div>
            {renaming ? (
              <DocumentRenameContainer>
                <RenameInput
                  type="text"
                  value={newFileName}
                  onChange={handleFileNameChange}
                />
                <Button onClick={() => handleSaveClick(index)} text={'Rename'} style={{ marginRight: 5 }} type={'main'} small />
              </DocumentRenameContainer>
            ) : (
              <>
                <DocumentLink
                  // style={{ color: 'var(--text)', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => downloadDoc(item?.id,
                    file,
                    isReportSource,
                    isLicenseSource,
                    isRenewalsReporting,
                    store?.currentLegislative?.id,
                    store?.currentLegislative?.report?.id,
                    false,
                    isNeedHistory,
                    licenseId
                  )}
                  target={'_blank'}
                  download>
                  {file}
                </DocumentLink>
                {/* <span></span> */}
              </>
            )}
          </div>
          {item.task &&
            <div style={{
              fontFamily: 'var(--regular)',
              fontSize: 14,
              color: '',
            }}>
              Task - {isReportSource || isLicenseSource ? item.task_description : item.task.description}
            </div>
          }
        </div>
        <DocumentRight>
          {(isReportSource || (isLicenseSource)) && showDelete &&
            <Dropdown placement="bottomRight" overlay={documentStatusMenu}>
              <div>Move ▼
              </div>
            </Dropdown>
          }
          {showDelete &&
            <a
              onClick={handleRenameClick}
              target={'_blank'}
              download>
              Rename
            </a>
          }
          <a
            onClick={() => downloadDoc(item?.id,
              file,
              isReportSource,
              isLicenseSource,
              isRenewalsReporting,
              store?.currentLegislative?.id,
              store?.currentLegislative?.report?.id,
              false,
              isNeedHistory,
              licenseId
            )}
            target={'_blank'}
            download>
            Download
          </a>
          {showDelete &&
            <div onClick={() => setShow(true)}>
              Delete
            </div>}
        </DocumentRight>
      </DocumentWrap >
      {show && (
        <Validation
          text={
            <div style={{ display: 'flex' }}>
              <Button
                text={'Cancel'}
                onClick={() => setShow(false)}
                style={{ marginRight: '.5em' }}
              />
              <Button
                text={'Delete'}
                onClick={() => {
                  isReportSource
                    ? store.deleteReportDocument(item?.report_company, item?.id, () => {
                      onDeleted(item.file_name)
                      store.loadActivity(legislative, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
                    })
                    : isLicenseSource
                      ? store.deleteLicenseDocument(isRenewalsReporting
                        ? item?.renewal_license
                        : item?.license_company, item?.id, () => {
                          onDeleted(item.file_name)
                          store.loadActivity(legislative, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
                        }, isRenewalsReporting)
                      : store.deleteDocument(item?.id, () => {
                        onDeleted(item.file_name)
                        store.loadActivity(legislative, 1, null, isReportSource, isLicenseSource, isRenewalsReporting, reportId)
                      })
                  setShow(false)
                }}
                style={{ marginLeft: '.5em' }}
                border
              />
            </div>
          }
          upperText={
            <>
              Are You sure that you want to delete{' '}
              <span style={{ color: 'var(--blue)' }}>{file}</span>?
              This cannot be undone.
            </>
          }
          noLineThrough
        />
      )
      }
    </>
  )
})

export default Document
